import ActionTypes from "highline/redux/action_types"
import { fetchNavigation } from "highline/api/navigation_api"
import { fromJS } from "immutable"
import Rollbar from "highline/utils/rollbar"

export const categoryNavigationItemClicked = (item) => ({
  type: ActionTypes.CATEGORY_NAVIGATION_ITEM_CLICKED,
  item,
})

export const categoryNavigationItemCollapsed = (item) => ({
  type: ActionTypes.CATEGORY_NAVIGATION_ITEM_COLLAPSED,
  item,
})

export const categoryNavigationItemExpanded = (item) => ({
  type: ActionTypes.CATEGORY_NAVIGATION_ITEM_EXPANDED,
  item,
})

export const categoryNavigationRequestStarted = () => ({
  type: ActionTypes.CATEGORY_NAVIGATION_REQUEST_STARTED,
})

export const categoryNavigationFetchSucceeded = (categories, path) => ({
  type: ActionTypes.CATEGORY_NAVIGATION_FETCH_SUCCEEDED,
  categories,
  path,
})

export const categoryNavigationFetchFailed = (error) => ({
  type: ActionTypes.CATEGORY_NAVIGATION_FETCH_FAILED,
  error,
})

export const categoryNavigationFetchAsync = (path) => async (dispatch, getState) => {
  const categoryNavItems = getState().getIn(["categoryNavigation", "categoryNavItems"])
  if (categoryNavItems.size > 0) return

  dispatch(categoryNavigationRequestStarted())

  try {
    const response = await fetchNavigation()
    const categories = response.data.get("categories")
    dispatch(categoryNavigationFetchSucceeded(categories, path))
  } catch (error) {
    dispatch(categoryNavigationFetchFailed(error))
  }
}
