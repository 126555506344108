import { useEffect, memo, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { fromJS } from "immutable"
import classNames from "classnames"

import TextTree from "highline/components/text_tree"
import NavigationModal from "highline/components/navigation_modal"
import { useViewportSize } from "highline/hooks/use_viewport_size"

import styles from "highline/styles/components/category/category_navigation.module.css"
import debounce from "lodash.debounce"

const DEFAULT_TOP = 80

const CategoryNavigation = ({
  expandedItemSiblings,
  activeItem,
  categoryNavItems,
  expandedItem,
  onClick,
  onCollapse,
  onExpand,
  onMount,
  showForSmartPhoneAndTablet,
  categoryIsCollection,
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const [topOffset, setTopOffset] = useState(DEFAULT_TOP)
  const { isSmartphone, isTablet } = useViewportSize()
  const isMobile = isTablet || isSmartphone
  const navRef = useRef(null)
  const resizeObserverRef = useRef()

  const updateTopOffset = useCallback(() => {
    if (isMobile) return

    if (typeof window !== undefined && navRef.current) {
      const { height } = navRef.current.getBoundingClientRect()
      const totalHeigh = height + DEFAULT_TOP
      const isOverflown = totalHeigh > window.innerHeight
      const newTopOffset = isOverflown ? window.innerHeight - (height + DEFAULT_TOP) : DEFAULT_TOP
      setTopOffset(newTopOffset)
    }
  }, [isMobile])

  // Update topOffset to default the first time.
  useEffect(() => {
    if (!isMounted) {
      onMount()
      updateTopOffset()
      setIsMounted(true)
    }
  }, [isMounted, onMount, updateTopOffset])

  // Listen to every resize and update offset
  useEffect(() => {
    const navEl = navRef.current
    const debounceUpdateTopOffset = debounce(updateTopOffset, 300)
    if (isMounted && !resizeObserverRef.current && navRef.current) {
      resizeObserverRef.current = new ResizeObserver(debounceUpdateTopOffset)
      resizeObserverRef.current.observe(navEl)
    }

    // Remove listener on unmount
    return () => {
      if (navEl && resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(navEl)
      }
    }
  }, [isMounted, updateTopOffset])

  return (
    <div
      ref={navRef}
      style={{ top: `${topOffset}px` }}
      className={classNames(
        "component",
        "category-navigation-component",
        styles.component,
        showForSmartPhoneAndTablet ? styles.smartPhoneAndTablet : styles.desktop
      )}
    >
      {isMobile && !categoryIsCollection && (
        <NavigationModal
          expandedItem={expandedItem}
          items={expandedItemSiblings}
          onClick={onClick}
        />
      )}

      {!isMobile && (
        <TextTree
          activeItem={activeItem}
          items={categoryNavItems}
          expandedItem={expandedItem}
          onClick={onClick}
          onCollapse={onCollapse}
          onExpand={onExpand}
        />
      )}
    </div>
  )
}

CategoryNavigation.propTypes = {
  expandedItemSiblings: ImmutablePropTypes.list,
  activeItem: ImmutablePropTypes.map,
  categoryNavItems: ImmutablePropTypes.list,
  categoryIsCollection: PropTypes.bool,
  expandedItem: ImmutablePropTypes.map,
  isTablet: PropTypes.bool,
  onClick: PropTypes.func,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  onMount: PropTypes.func,
  showForSmartPhoneAndTablet: PropTypes.bool,
}

CategoryNavigation.defaultProps = {
  expandedItemSiblings: fromJS([]),
  categoryNavItems: fromJS([]),
  categoryIsCollection: false,
  isTablet: false,
  onClick: () => {},
  onCollapse: () => {},
  onExpand: () => {},
  onMount: () => {},
  showForSmartPhoneAndTablet: false,
}

const MemoizedCategoryNavigation = memo(CategoryNavigation)
MemoizedCategoryNavigation.displayName = "CategoryNavigation"

export default MemoizedCategoryNavigation
