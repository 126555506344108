import { Record } from "immutable"
import { isProductAFielderProduct } from "highline/redux/helpers/product_detail_helper"

const schema = {
  amount: null,
  bundleDiscount: false,
  canAdjustQuantity: true,
  description: null,
  description2: null,
  discountedBundles: null,
  discountedTotal: null,
  finalSale: null,
  fullPrice: null,
  fullPriceNumeric: null,
  id: null,
  image: null,
  isFielderProduct: false,
  isGiftCard: false,
  inStock: true,
  isPreorder: false,
  itemId: null,
  limitedQuantity: null,
  name: null,
  newQuantity: null,
  onSale: false,
  options: null,
  path: null,
  price: null,
  priceNumeric: null,
  productId: null,
  quantity: null,
  sku: null,
  slug: null,
  subtotalNumeric: null,
}

const isDigitalGiftCard = (lineItem) =>
  lineItem.getIn(["giftCardDetails", "giftCard"]) && lineItem.getIn(["giftCardDetails", "digital"])

const isFielderProduct = (lineItem) => isProductAFielderProduct(lineItem.get("slug", ""))

const digitalGiftCardLineItem = (lineItem) =>
  lineItem.merge({
    description: lineItem.getIn(["giftCardDetails", "recipientInfo"]),
    description2: lineItem.getIn(["giftCardDetails", "deliveryInfo"]),
    canAdjustQuantity: false,
  })
class LineItemRecord extends Record(schema) {
  constructor(lineItemResponse) {
    let lineItem = isDigitalGiftCard(lineItemResponse)
      ? digitalGiftCardLineItem(lineItemResponse)
      : lineItemResponse

    // additional check if line item is a fielder product
    lineItem = lineItem.merge({
      isFielderProduct: isFielderProduct(lineItemResponse),
    })

    super(lineItem)
  }
}

export default LineItemRecord
