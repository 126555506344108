import { fromJS } from "immutable"
import getConfig from "highline/config/application"

export const orderStateDisplayText = (state, delivered) => {
  if (!(typeof state === "string")) return ""

  if (delivered) return "Your order has been delivered!"

  const lowercaseState = state.toLowerCase()

  switch (lowercaseState) {
    case "ready":
      return "Your order is being prepared at our warehouse"
    case "pending":
      return "We are working on your order"
    case "shipped":
      return "Your order is on its way!"
    case "cancelled":
      return "This order has been cancelled"
    default:
      return lowercaseState.charAt(0).toUpperCase() + lowercaseState.slice(1)
  }
}

export const hasAttachedCreditCardWithOnlyFailedPayments = (order) => {
  const attachedCreditCard = order.get("creditCard")
  const payments = order.get("payments")
  if (!attachedCreditCard || attachedCreditCard.isEmpty() || !payments) {
    return false
  }
  const paymentsFromAttachedCreditCard = payments.filter((payment) => {
    return (
      payment.get("sourceId") === attachedCreditCard.get("id") &&
      payment.get("sourceType") === "Spree::CreditCard"
    )
  })
  const allPaymentsForAttachedCreditCardAreFailing = paymentsFromAttachedCreditCard.every(
    (payment) => {
      return payment.get("state") === "failed"
    }
  )
  return allPaymentsForAttachedCreditCardAreFailing
}

export const createErrorForAttachedCreditCardWithOnlyFailedPayments = (order) => {
  const attachedCreditCardLastDigits = order.getIn(["creditCard", "lastDigits"])
  const creditCardText = attachedCreditCardLastDigits
    ? ` ending in ${attachedCreditCardLastDigits}`
    : ""
  const errorText = `There was an issue with your credit card${creditCardText}. Please enter payment details in again.`

  return fromJS({
    errors: {
      creditCard: [errorText],
    },
  })
}

export const findGiftCardPayment = (payments) => {
  if (!payments) {
    return
  }
  return payments.find(
    (payment) =>
      payment.get("sourceType") === "Spree::GiftCard" && payment.get("state") !== "invalid"
  )
}
