import getConfig from "highline/config/application"
const { isProduction, isFeatureMode } = getConfig()

const isProductionHighline = isProduction && !isFeatureMode

const ADYEN_CSE_JS_PRODUCTION = "https://live.adyen.com/hpp/cse/js/2614842449560879.shtml"
const ADYEN_CSE_JS_TEST = "https://test.adyen.com/hpp/cse/js/7514712955425108.shtml"
export const adyenCSE = isProductionHighline ? ADYEN_CSE_JS_PRODUCTION : ADYEN_CSE_JS_TEST

const ADYEN_HTTP_URL_PRODUCTION = "https://live.adyen.com/hpp/skipDetails.shtml"
const ADYEN_HTTP_URL_TEST = "https://test.adyen.com/hpp/skipDetails.shtml"
export const adyenUrl = isProductionHighline ? ADYEN_HTTP_URL_PRODUCTION : ADYEN_HTTP_URL_TEST

const ADYEN_KEY_PRODUCTION =
  "10001|D56541B3BB4E8FAE22F7BD9DECBFB5407601D402C71EB0F3778E4D1D173DFE85D1A2C64F1F10ED5448099E0DC84375CA164F5B61AA311F53D851C8E5BCC8D768410171179F317C376E931539FB9C97B990C88D3918D2C0214D7CA0088AC4051D99321BEAD0D86D107DCF6FEB69ED3DE47EBE6E05DA853F9CC15657F13FB5FFBFCCDD6A8BC477302B3A5019C4E2B3D732916499ABBEE05EA36FC4177042B2C5ECC53DCD2966EAA0C52704D3EB19AB3676694CA29E4DB5A354B7FBB097181FF9999D2A96AB02EB1B31480524C97062DA5E8F809CEBF4F2AFAC43375C1F627D4D844F75616ED36CD67FEDFB1591CE33D44BFCCCC7D8169C7FCE8DA8EE807120DCDD"
const ADYEN_KEY_TEST =
  "10001|B9724A8DA7915F0884B495F4A0833B6940A9E9ADD368675148A226C3BA5954269793B421ED7AB5214CA7BB60E55816067A8AF3B69A918E1AB6396CC0BE4C76ADE1A7208E0D73FC95360E1D46027B365FE68A79FEADC86D776C2D88ACF6D91EB044129BF1DF082849A01B0D135172CB6484DBDEDC86659C09850810776B8F584F9D2586FB2E194A5E2B3BE57CEA7A8FC57FDB5F5955D3EA83AAD539903EAFD8E089A7CFDEA05D642C8DB692409C448ACAF580CCD3C056D5BC71D7F41C5F166CDAA2E11B5820E2375E68D11F218DDF2D3B3109EF373ED0B0A3C112A90C903119DF03862B8CCFDDB6BCAB89C5FE69E2B1596B4CDD8269138ADE32F1C2DC759C7BFF"
export const adyenKey = isProductionHighline ? ADYEN_KEY_PRODUCTION : ADYEN_KEY_TEST

export const DEFAULT_PAGE_REVALIDATION_TIME = 60 * 10 // ten min in seconds

export const OPT_IN_OUT_TYPES = {
  INFECTION: "Infection",
  GPC_PROPAGATION: "GPC_PROPAGATION",
}

export const OPT_STATE = {
  IN: "OPT_IN",
  OUT: "OPT_OUT",
}

// load status constants
export const LOAD_STATUS = {
  INITIAL: "initial",
  PENDING: "pending",
  SUCCEEDED: "succeeded",
  FAILED: "errored",
}
