import { fromJS } from "immutable"
import ActionTypes from "highline/redux/action_types"
import {
  buildBundleClassifications,
  buildBundleOptions,
  getSwatches,
  isBundleAllFinalSale,
  isBundleOfSameProduct,
  updateBundleOptions,
  updateCollapsedOptions,
} from "highline/redux/helpers/bundle_detail_helper"
import {
  deduplicateProductProperties,
  ensureImagesAreObjects,
  updatePriceWithPromo,
  camelizeOptionNames,
} from "highline/redux/helpers/product_detail_helper"
import { incrementOrDecrementZoomIndex } from "../helpers/product_detail_helper"
import { bundleSlugsExclusions } from "highline/utils/promo_auto_apply_helper.js"

const initialState = fromJS({
  bundleClassifications: {},
  bundleOptions: {},
  collapsedOptions: [],
  contentful: [],
  currentProductOptions: [],
  currentSwatchIndex: 0,
  finalSale: false,
  hasCompletedSwatchSelection: false,
  images: [],
  isAddingToCart: false,
  isLoading: false,
  isReady: false,
  isSameProduct: false,
  isSuit: false,
  metaDescription: "",
  metaTitle: "",
  name: "",
  price: {},
  products: [],
  properties: {},
  requestedOptions: {},
  shortDescription: "",
  showAddToCartError: false,
  slug: "",
  swatchGroupData: [],
  url: "",
  variant: {},
  visitedSwatches: [],
  zoomImageIndex: 0,
  zoomImageUrl: null,
  zoomOpen: false,
})

const bundleDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.BUNDLE_DETAIL_FETCH_SUCCEEDED: {
      state = state.merge(action.bundle)
      const products = state.get("products").map((product) => {
        const imagesAsObjects = ensureImagesAreObjects(product.get("images"))
        const properties = deduplicateProductProperties(product.get("properties"))
        return product.merge({
          images: imagesAsObjects,
          options: camelizeOptionNames(product.get("options")),
          properties,
        })
      })
      const isSameProduct = isBundleOfSameProduct(products)
      const currentSwatchIndex = state.get("currentSwatchIndex")
      const isSuit = state.get("isSuit")
      const bundleOptions = buildBundleOptions(products, isSameProduct, currentSwatchIndex, isSuit)
      const images = ensureImagesAreObjects(state.get("images"))
      let collapsedOptions = state.get("collapsedOptions")

      // On first fetch, construct a boolean array with the first element true and
      // the rest false. On subsequent fetches, grab current array from state.
      const visitedSwatches =
        state.get("visitedSwatches").size === 0
          ? products.map((p, i) => i === 0)
          : state.get("visitedSwatches")

      const swatchGroupData = getSwatches(products, visitedSwatches)
      const finalSale = isBundleAllFinalSale(products)

      if (!action.isFromOptionChange) {
        // Collapse the options that already have a selection
        // only on page load, not after a user makes a change to the options.
        collapsedOptions = updateCollapsedOptions(products)
      }

      // Set the product options for the selected swatch position
      if (isSameProduct || !isSuit) {
        state = state.set(
          "currentProductOptions",
          camelizeOptionNames(products.getIn([currentSwatchIndex, "options"]))
        )
      }
      const exclusions = fromJS({ ...action.exclusions.toJS(), ...bundleSlugsExclusions })
      state = updatePriceWithPromo(state, action.promo, true, exclusions)

      return state.merge({
        bundleOptions,
        collapsedOptions,
        images,
        isReady: true,
        isSameProduct,
        isSuit,
        products,
        showAddToCartError: false,
        swatchGroupData,
        finalSale,
        visitedSwatches,
        zoomImageUrl: null,
        zoomOpen: false,
        requestedOptions: fromJS({}),
      })
    }

    case ActionTypes.PROMOTION_EXCLUSIONS_FETCH_SUCCEEDED: {
      const exclusions = fromJS({ ...action.data.toJS(), ...bundleSlugsExclusions })
      return updatePriceWithPromo(state, action.promo, true, exclusions)
    }

    case ActionTypes.BUNDLE_DETAIL_ZOOM_OPEN_CLICKED:
      return state.merge({
        zoomImageIndex: action.zoomImageIndex,
        zoomImageUrl: action.url,
        zoomOpen: true,
      })

    case ActionTypes.BUNDLE_DETAIL_ZOOM_CLOSE_CLICKED:
      return state.merge({
        zoomImageIndex: 0,
        zoomImageUrl: null,
        zoomOpen: false,
      })

    case ActionTypes.BUNDLE_DETAIL_PREV_IMAGE_CLICKED: {
      const swatchIndex = state.get("currentSwatchIndex")
      // Find the images for the product in the bundle that's currently being displayed
      const images = state.getIn(["products", swatchIndex, "images"])
      const newImageIndex = incrementOrDecrementZoomIndex(state, images, -1)
      return state.merge({
        zoomImageIndex: newImageIndex,
        zoomImageUrl: images.getIn([newImageIndex, "url"]),
      })
    }

    case ActionTypes.BUNDLE_DETAIL_NEXT_IMAGE_CLICKED: {
      const swatchIndex = state.get("currentSwatchIndex")
      // Find the images for the product in the bundle that's currently being displayed
      const images = state.getIn(["products", swatchIndex, "images"])

      const newImageIndex = incrementOrDecrementZoomIndex(state, images)
      return state.merge({
        zoomImageIndex: newImageIndex,
        zoomImageUrl: images.getIn([newImageIndex, "url"]),
      })
    }

    case ActionTypes.BUNDLE_DETAIL_OPTIONS_CHANGED: {
      const bundleOptions = state.get("bundleOptions")
      const newBundleOptions = updateBundleOptions(
        bundleOptions,
        action.optionName,
        action.optionValue,
        action.productSlug,
        action.productPosition
      )

      return state.set("bundleOptions", newBundleOptions)
    }

    case ActionTypes.BUNDLE_DETAIL_REQUESTED_WITH_OPTIONS:
      return state.set("requestedOptions", action.requestedOptions)

    case ActionTypes.BUNDLE_DETAIL_FETCH_STARTED:
      return state.set("isLoading", true)

    case ActionTypes.BUNDLE_DETAIL_FETCH_COMPLETED:
      return state.set("isLoading", false)

    case ActionTypes.BUNDLE_DETAIL_ADD_TO_CART_CLICKED: {
      return !state.get("variant") ||
        (state.get("isSameProduct") && !state.get("hasCompletedSwatchSelection"))
        ? state
        : state.merge({
            isAddingToCart: true,
            showAddToCartError: false,
          })
    }

    case ActionTypes.SIZE_AND_FIT_CLASSIFICATIONS_FETCH_SUCCEEDED: {
      const bundleClassifications = buildBundleClassifications(action.classifications)
      return state.merge({ bundleClassifications })
    }

    case ActionTypes.BUNDLE_DETAIL_OPTION_TOGGLED: {
      const collapsedOptions = state.get("collapsedOptions")
      if (collapsedOptions.includes(action.optionName)) {
        const optionIndex = collapsedOptions.indexOf(action.optionName)
        return state.set("collapsedOptions", collapsedOptions.splice(optionIndex, 1))
      } else {
        return state.set("collapsedOptions", collapsedOptions.push(action.optionName))
      }
    }

    case ActionTypes.BUNDLE_DETAIL_SWATCH_CHANGED: {
      // If the current tabbed swatch is not in the set of visitedSwatches,
      // add it and update the swatch group data to reflect that.
      const index = parseInt(action.index)

      // Set the product options for the selected swatch position
      const products = state.get("products")
      state = state.set(
        "currentProductOptions",
        camelizeOptionNames(products.getIn([index, "options"]))
      )
      const isSuit = state.get("isSuit")

      // rebuild bundle options when moving to a different swatch
      const bundleOptions = buildBundleOptions(products, state.get("isSameProduct"), index, isSuit)

      if (!state.getIn(["visitedSwatches", index])) {
        const visitedSwatches = state.get("visitedSwatches").set(index, true)
        const swatchGroupData = getSwatches(products, visitedSwatches)

        return state.merge({
          currentSwatchIndex: index,
          visitedSwatches,
          swatchGroupData,
          hasCompletedSwatchSelection: visitedSwatches.toSet().size === 1 && visitedSwatches.get(0), // Have we visited each of the tabs?
          bundleOptions,
        })
      }

      return state.merge({
        currentSwatchIndex: index,
        bundleOptions,
      })
    }

    case ActionTypes.BUNDLE_DETAIL_USER_SELECTION_CLEARED: {
      const slug = state.getIn(["products", action.index, "slug"])
      const color = state.getIn(["products", action.index, "selectedOptions", "color"])
      // clear selected variant
      state = state.setIn(["products", action.index, "variant"], null)
      // clear selected options from products key
      state = state.setIn(["products", action.index, "selectedOptions"], fromJS({ color }))
      // clear selected options from bundleOptions
      state = state.setIn(
        ["bundleOptions", slug, "selectedOptions", action.index],
        fromJS({ color })
      )
      return state
    }

    case ActionTypes.LINE_ITEMS_ADDED_TO_CART:
      return state.set("isAddingToCart", false)

    case ActionTypes.CART_ADD_LINE_ITEMS_FAILED:
      return state.merge({
        isAddingToCart: false,
        showAddToCartError: true,
      })

    case ActionTypes.CLIENT_ROUTE_CHANGE_STARTED:
      return state.merge({
        currentSwatchIndex: 0,
        visitedSwatches: [],
      })

    default:
      return state
  }
}

export default bundleDetailReducer
