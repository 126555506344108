import { AB_TESTS } from "highline/utils/abtests"

// guaranteed time for ab tasty tag to load is under 200ms
// https://abtasty.zendesk.com/hc/en-us/articles/200331427-What-is-your-SLA-Service-Level-Agreement-
const AB_TASTY_SLA = 300 // additional buffer for guaranteed response

export const isFeatureEnabled = (testName) => {
  const aBTastyIsDefined =
    typeof window !== "undefined" &&
    typeof window.ABTasty !== "undefined" &&
    typeof window.ABTasty.getTestsOnPage === "function"
  if (!aBTastyIsDefined) return false

  const testArray = Object.values(window.ABTasty.getTestsOnPage())
  const selectedTestObject = testArray.filter(
    (test) => test.name === AB_TESTS[testName] || test.name === testName
  )[0]
  const isInTestVariation = selectedTestObject && selectedTestObject.variationName === "Variation 1"

  return !!isInTestVariation
}

export const fetchABTastyTests = () => {
  if (typeof window == "undefined" || !window.ABTasty || !window.ABTasty.getTestsOnPage)
    return new Promise((resolve) => resolve())

  // eslint-disable-next-line compat/compat
  return new Promise((resolve) =>
    window.setTimeout(() => {
      const testsOnPage =
        window.ABTasty && window.ABTasty.getTestsOnPage ? window.ABTasty.getTestsOnPage() : {}

      const remappedTests = remapABTastyTestsOnPage(testsOnPage)
      resolve(remappedTests)
    }, AB_TASTY_SLA)
  )
}

export const remapABTastyTestsOnPage = (tests) => {
  const remappedTests = {}
  Object.values(tests).forEach((test) => {
    if (test.name && test.variationName) {
      remappedTests[test.name] = test.variationName
    }
  })
  return remappedTests
}
