import { fromJS } from "immutable"
import ActionTypes from "highline/redux/action_types"
import * as OrderStorage from "highline/storage/order_storage"
import { getNewMergedOrder } from "highline/redux/helpers/checkout_helper"
import { isFeatureEnabled } from "highline/utils/abtasty_helper"
import { isServer } from "highline/utils/client"
import getConfig from "highline/config/application"

const { isGuestCheckoutEnabled } = getConfig()
const initialState = fromJS({
  address: {
    address1: "",
    address2: "",
    city: "",
    country: {
      code: "US",
      name: "United States",
    },
    firstName: "",
    id: null,
    lastName: "",
    phone: "",
    postalCode: {
      code: "",
      label: "Zip",
    },
    region: {
      code: "",
      label: "State",
      name: "",
    },
  },
  adyenMerchantSignature: "",
  availableShippingRates: [],
  creditCard: {},
  currentStep: 0,
  cvvValidationRequired: false,
  email: "",
  errorStatusCode: null,
  estimatedTotalIsZero: false,
  gift: false,
  giftNote: null,
  isCompletedGuestOrderLinkedToAccount: false,
  isEditingShippingOnReview: false,
  isEditingBillingOnReview: false,
  isFirstCompletedOrder: false,
  isFullPageCheckout: false,
  isInitialLoad: true,
  isLastStep: false,
  isLoading: true,
  isOrderCompleted: false,
  isOrderSummaryOpen: false,
  isSubmittingOrder: false,
  isTaxCalculated: false,
  itemCount: 0,
  items: [],
  markdown: "",
  number: "",
  paidWithAffirm: false,
  payments: [],
  paypalInputs: {},
  promoCodeDetails: {
    code: "",
    error: {},
    isLoading: false,
    isPromoCodeApplied: false,
  },
  promotion: {},
  shipment: {},
  shippingRate: {},
  signifydSessionId: "",
  showErrorOnEmail: false,
  storeCreditRemainingAfterCapture: "",
  storeCreditTotal: "",
  storeCreditTotalNumeric: 0,
  subtotal: "",
  subtotalNumeric: "",
  surchargeTotal: "",
  surchargeTotalNumeric: 0,
  taxTotal: "",
  taxTotalNumeric: "",
  token: "",
  total: "",
  totalNumeric: "",
  bundleDiscountTotal: "",
  bundleDiscountTotalNumeric: 0,
})

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AB_TESTS_FETCHED: {
      const guestCheckoutABTestActive =
        action.abTests["Single-Page-Guest-Checkout"] === "Variation 1"

      return state.set(
        "isGuestCheckoutEnabled",
        isGuestCheckoutEnabled && guestCheckoutABTestActive
      )
    }

    case ActionTypes.PAGE_LOADED: {
      if (isServer) return state

      return state.set("isFullPageCheckout", window.location.pathname === "/checkout/details")
    }

    case ActionTypes.CHECKOUT_STEP_VIEWED: {
      // set editing to false for shipping information if we already have a shipping address selected
      const hasAddress = state.getIn(["address", "address1"], null)
      if (hasAddress) {
        return state.set("isEditingShippingOnReview", false)

        // otherwise, we should be editing if it's blank
      } else {
        return state.set("isEditingShippingOnReview", true)
      }
    }

    case ActionTypes.UPDATE_ORDER_ADDRESS_SUCCEEDED:
    case ActionTypes.ORDER_DELETE_LINE_ITEM_SUCCEEDED:
    case ActionTypes.BILLING_INFORMATION_ADD_SUCCEEDED:
    case ActionTypes.SHIPPING_INFORMATION_ADD_SUCCEEDED:
    case ActionTypes.APPLE_PAY_SHIPPING_INFORMATION_ADD_SUCCEEDED:
    case ActionTypes.ORDER_UPDATE_SUCCEEDED:
    case ActionTypes.ORDER_FETCH_SUCCEEDED:
    case ActionTypes.REMOVE_GIFT_CARD_REQUEST_SUCCEEDED: {
      const { signifydSessionId } = OrderStorage.load()

      const updateEditShipping =
        action.type === ActionTypes.SHIPPING_INFORMATION_ADD_SUCCEEDED
          ? { isEditingShippingOnReview: false }
          : {}
      let order = action.order
      const hasPhysicalGift = action.order
        .get("items")
        ?.find((item) => item.get("productId") === "GIFTCARDC")

      if (hasPhysicalGift !== undefined) {
        const availableShippingRates = action.order
          .get("availableShippingRates")
          .filter((sr) => sr.get("code") !== "208" && sr.get("code") !== "209")

        let shippingRate = action.order.get("shippingRate")

        const shippingRateCode = shippingRate.get("code")
        if (shippingRateCode === "208" || shippingRateCode === "209") {
          shippingRate = availableShippingRates.first()
        }
        order = action.order
          .set("availableShippingRates", availableShippingRates)
          .set("shippingRate", shippingRate)
      }

      return getNewMergedOrder(state, order).merge({
        isLoading: false,
        signifydSessionId,
        ...updateEditShipping,
      })
    }

    case ActionTypes.ORDER_SUMMARY_TOGGLED:
      return state.merge({
        isOrderSummaryOpen: !state.get("isOrderSummaryOpen"),
      })

    case ActionTypes.ORDER_EMAIL_UPDATE_FAILED: {
      return state.set("showErrorOnEmail", true)
    }

    case ActionTypes.ORDER_EMAIL_UPDATE_SUCCEEDED:
      return state.merge({
        email: action.email,
        showErrorOnEmail: false,
      })

    case ActionTypes.SHIPPING_INFORMATION_INPUT_CHANGED: {
      return action.name === "email" ? state.set("email", action.value) : state
    }

    case ActionTypes.ORDER_STEP_LOCATION_CHANGED:
    case ActionTypes.SHIPPING_INFORMATION_REQUEST_STARTED:
    case ActionTypes.APPLE_PAY_SHIPPING_INFORMATION_REQUEST_STARTED:
    case ActionTypes.BILLING_INFORMATION_REQUEST_STARTED:
    case ActionTypes.ORDER_REQUEST_STARTED:
    case ActionTypes.APPLE_PAY_ORDER_REQUEST_STARTED:
      return state
        .set("isLoading", true)
        .set(
          "isGuestCheckoutEnabled",
          isGuestCheckoutEnabled && isFeatureEnabled("singlePageGuestCheckout")
        )

    case ActionTypes.SUBMIT_ORDER_REQUEST_STARTED:
    case ActionTypes.APPLE_PAY_SUBMIT_ORDER_REQUEST_STARTED:
      return state.merge({
        isLoading: true,
        isSubmittingOrder: true,
      })

    case ActionTypes.ORDER_PROMO_CODE_INPUT_CHANGED:
      return state
        .setIn(["promoCodeDetails", action.name], action.value)
        .setIn(["promoCodeDetails", "error"], {})

    case ActionTypes.ORDER_SUBMIT_COMPLETE_SUCCEEDED:
    case ActionTypes.APPLE_PAY_ORDER_SUBMIT_COMPLETE_SUCCEEDED:
      return getNewMergedOrder(state, action.order).merge({
        isOrderCompleted: true,
        isOrderSummaryOpen: true,
      })

    case ActionTypes.ORDER_CONFIRMATION_UNMOUNTED:
      return state.set("isOrderCompleted", false)

    case ActionTypes.ORDER_SUBMIT_PROMO_CODE_SUCCEEDED:
    case ActionTypes.ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED:
    case ActionTypes.APPLE_PAY_ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED:
      return getNewMergedOrder(state, action.order).set("isLoading", false)

    case ActionTypes.ORDER_FATAL_ERROR_RECEIVED:
    case ActionTypes.APPLE_PAY_ORDER_FATAL_ERROR_RECEIVED:
      return state.set("errorStatusCode", action.errorStatusCode)

    case ActionTypes.BILLING_INFORMATION_ADD_FAILED:
    case ActionTypes.SHIPPING_INFORMATION_REQUEST_COMPLETED:
    case ActionTypes.ORDER_REQUEST_COMPLETED:
    case ActionTypes.APPLE_PAY_ORDER_REQUEST_COMPLETED:
      return state.merge({
        isLoading: false,
        isSubmittingOrder: false,
      })

    case ActionTypes.ORDER_SUBMIT_PROMO_CODE_FAILED:
      return state.set("isLoading", false).setIn(["promoCodeDetails", "error"], action.error)

    case ActionTypes.ORDER_SUBMIT_COMPLETE_FAILED:
    case ActionTypes.APPLE_PAY_ORDER_SUBMIT_COMPLETE_FAILED:
    case ActionTypes.ORDER_DELETE_LINE_ITEM_FAILED:
      return state.set("isLoading", false).set("error", action.error)

    case ActionTypes.ORDER_SHIPPING_RATE_CHANGED_FAILED:
      return state.merge({
        error: action.error,
        shippingRate: action.shippingRate,
      })

    case ActionTypes.ORDER_NOT_FOUND:
      return initialState

    case ActionTypes.TOGGLE_REVIEW_SHIPPING_EDIT:
      return state.set("isEditingShippingOnReview", !state.get("isEditingShippingOnReview"))

    case ActionTypes.TOGGLE_REVIEW_BILLING_EDIT:
      return state.set("isEditingBillingOnReview", !state.get("isEditingBillingOnReview"))

    case ActionTypes.BILLING_INFORMATION_REQUEST_COMPLETED:
    case ActionTypes.BILLING_INFORMATION_REVIEW_NAVIGATE_CLICKED:
      return state.set("isEditingBillingOnReview", false)

    case ActionTypes.ORDER_SHIPPING_RATE_CHANGED_STARTED:
      return state.merge({
        isLoading: true,
        shippingRate: action.shippingRate,
      })

    default:
      return state
  }
}

export default orderReducer
