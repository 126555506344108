import ActionTypes from "highline/redux/action_types"

export const promotionExclusionsFetchSucceeded = (code, data, promo) => {
  return {
    type: ActionTypes.PROMOTION_EXCLUSIONS_FETCH_SUCCEEDED,
    code,
    data,
    promo,
  }
}

export const promotionExclusionsFetchStarted = (code) => {
  return {
    type: ActionTypes.PROMOTION_EXCLUSIONS_FETCH_STARTED,
    code,
  }
}

export const promotionExclusionsFetchFailed = (code, error) => {
  return {
    type: ActionTypes.PROMOTION_EXCLUSIONS_FETCH_FAILED,
    code,
    error,
  }
}
