import keyMirror from "highline/utils/key_mirror"

export default keyMirror({
  AB_TASTY_LOADED: null,
  AB_TESTS_FETCHED: null,
  AB_TESTS_FETCH_FAILED: null,
  ACCOUNT_LOGGED_IN: null,
  ACCOUNT_NAV_ITEM_CLICKED: null,
  ACCOUNT_CANCEL_RECENT_ORDER_CLICKED: null,
  ACCOUNT_CANCEL_RECENT_ORDER_FAILED: null,
  ACCOUNT_CANCEL_RECENT_ORDER_SUCCEEDED: null,
  ACCOUNT_ORDER_CANCEL_CLICKED: null,
  ACCOUNT_ORDER_DETAIL_TOGGLED: null,
  ACCOUNT_ORDER_EXCHANGE_CLICKED: null,
  ACCOUNT_ORDER_HISTORY_FETCH_FAILED: null,
  ACCOUNT_ORDER_HISTORY_FETCH_STARTED: null,
  ACCOUNT_ORDER_HISTORY_FETCH_SUCCEEDED: null,
  ACCOUNT_ORDER_PRINT_RETURN_LABEL_CLICKED: null,
  ACCOUNT_ORDER_RETURN_TOGGLED: null,
  ACCOUNT_ORDER_TRACK_CLICKED: null,
  ACCOUNT_PAGE_LOADED: null,
  ACCOUNT_REDEEM_GIFT_CERTIFICATE_FAILED: null,
  ACCOUNT_REDEEM_GIFT_CERTIFICATE_STARTED: null,
  ACCOUNT_REDEEM_GIFT_CERTIFICATE_SUCCEEDED: null,
  ACCOUNT_STORE_CREDIT_FETCH_FAILED: null,
  ACCOUNT_STORE_CREDIT_FETCH_STARTED: null,
  ACCOUNT_STORE_CREDIT_FETCH_SUCCEEDED: null,
  ACCOUNT_REPORT_LINK_FETCH_STARTED: null,
  ACCOUNT_REPORT_LINK_FETCH_FAILED: null,
  ACCOUNT_REPORT_LINK_FETCH_SUCCEEDED: null,
  AFFIRM_CHECKOUT_FAILED: null,
  AFFIRM_CHECKOUT_MODAL_CLOSED: null,
  AFFIRM_CHECKOUT_MODAL_OPENED: null,
  AFFIRM_CHECKOUT_SUCCEEDED: null,
  AFFIRM_ESTIMATE_CLICKED: null,
  APPLE_PAY_MERCHANT_VALIDATION_COMPLETED: null,
  APPLE_PAY_MERCHANT_VALIDATION_FAILED: null,
  APPLE_PAY_MERCHANT_VALIDATION_STARTED: null,
  APPLE_PAY_ORDER_FATAL_ERROR_RECEIVED: null,
  APPLE_PAY_ORDER_REQUEST_COMPLETED: null,
  APPLE_PAY_ORDER_REQUEST_STARTED: null,
  APPLE_PAY_ORDER_SHIPPING_RATE_CHANGED_FAILED: null,
  APPLE_PAY_ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED: null,
  APPLE_PAY_ORDER_STEP_LOCATION_CHANGED: null,
  APPLE_PAY_ORDER_SUBMIT_COMPLETE_FAILED: null,
  APPLE_PAY_ORDER_SUBMIT_COMPLETE_SUCCEEDED: null,
  APPLE_PAY_SESSION_CREATED: null,
  APPLE_PAY_SESSION_FAILED: null,
  APPLE_PAY_SESSION_STARTED: null,
  APPLE_PAY_SHIPPING_INFORMATION_ADD_FAILED: null,
  APPLE_PAY_SHIPPING_INFORMATION_ADD_SUCCEEDED: null,
  APPLE_PAY_SHIPPING_INFORMATION_REQUEST_STARTED: null,
  APPLE_PAY_SUBMIT_ORDER_REQUEST_STARTED: null,
  AUTH_FAILED: null,
  AUTH_FLOW_STARTED: null,
  AUTH_INPUT_CHANGED: null,
  AUTH_OPENED: null,
  AUTH_REQUEST_COMPLETED: null,
  AUTH_REQUEST_STARTED: null,
  AUTH_VALIDATION_FAILED: null,
  BROWSER_HISTORY_NAVIGATED: null,
  BUNDLE_DETAIL_ADD_TO_CART_CLICKED: null,
  BUNDLE_DETAIL_FETCH_COMPLETED: null,
  BUNDLE_DETAIL_FETCH_FAILED: null,
  BUNDLE_DETAIL_FETCH_STARTED: null,
  BUNDLE_DETAIL_FETCH_SUCCEEDED: null,
  BUNDLE_DETAIL_HELP_LINK_CLICKED: null,
  BUNDLE_DETAIL_LOCATION_CHANGED: null,
  BUNDLE_DETAIL_MODAL_VIEW_PRODUCT_CLICKED: null,
  BUNDLE_DETAIL_NEXT_IMAGE_CLICKED: null,
  BUNDLE_DETAIL_OPTION_TOGGLED: null,
  BUNDLE_DETAIL_OPTIONS_CHANGED: null,
  BUNDLE_DETAIL_PREV_IMAGE_CLICKED: null,
  BUNDLE_DETAIL_PRODUCT_INFO_MODAL: null,
  BUNDLE_DETAIL_PRODUCT_PROPERTIES_ACCORDION_CLICKED: null,
  BUNDLE_DETAIL_REQUESTED_WITH_OPTIONS: null,
  BUNDLE_DETAIL_SIZE_AND_FIT_CTA_CLICKED: null,
  BUNDLE_DETAIL_SWATCH_CHANGED: null,
  BUNDLE_DETAIL_THUMBNAIL_CLICKED: null,
  BUNDLE_DETAIL_USER_SELECTION_CLEARED: null,
  BUNDLE_DETAIL_ZOOM_CLICKED: null,
  BUNDLE_DETAIL_ZOOM_CLOSE_CLICKED: null,
  BUNDLE_DETAIL_ZOOM_OPEN_CLICKED: null,
  BUNDLE_DISCOUNT_BUNDLE_PURCHASED: null,
  BUNDLE_DISCOUNT_CROSS_CATEGORY_BUNDLE_PURCHASED: null,
  BUNDLE_PRODUCT_SEPARATE_CLICKED: null,
  CAMPAIGN_PAGE_LINK_CLICKED: null,
  CAMPAIGN_PAGE_VIEWED: null,
  CAROUSEL_CLICKED: null,
  CAROUSEL_SCROLLED: null,
  CART_ADD_LINE_ITEMS_FAILED: null,
  CART_ADD_LINE_ITEMS_STARTED: null,
  APPLE_PAY_BUTTON_CLICKED: null,
  CART_BACK_CLICKED: null,
  CART_CLOSE_CLICKED: null,
  CART_EMPTY: null,
  CART_ADD_AS_GIFT_FAILED: null,
  CART_ADD_AS_GIFT_STARTED: null,
  CART_ADD_AS_GIFT_SUCCEEDED: null,
  CART_GIFT_NOTE_INPUT_CHANGED: null,
  CART_IS_GIFT_INPUT_TOGGLED: null,
  CART_ITEM_ADDED_TO_WISHLIST: null,
  CART_LOADED: null,
  CART_LOAD_FAILED: null,
  CART_LOAD_ITEM_COUNT_FAILED: null,
  CART_LOAD_ITEM_COUNT_COMPLETED: null,
  CART_LOAD_ITEM_COUNT_STARTED: null,
  CART_LOAD_ITEM_COUNT_SUCCEEDED: null,
  CART_NOTIFICATION_CLICKED: null,
  CART_NOTIFICATION_DISMISSED: null,
  CART_NOTIFICATIONS_LOADED: null,
  CART_NOTIFICATIONS_LOADED_EMPTY: null,
  CART_NOT_FETCHED: null,
  CART_NOT_FOUND: null,
  CART_PAGE_OPENED: null,
  CART_PAYPAL_BUTTON_CLICKED: null,
  CART_PROMO_APPLIED: null,
  CART_PROMO_AUTO_APPLIED: null,
  CART_PROMO_AUTO_REMOVED: null,
  CART_PROMO_FAILED: null,
  CART_PROMO_FIELD_TOGGLED: null,
  CART_PROMO_CODE_INPUT_CHANGED: null,
  CART_PROMO_STARTED: null,
  CART_PROMO_REMOVED: null,
  CART_PROMO_ERROR_TIMED_OUT: null,
  CART_REQUEST_COMPLETED: null,
  CART_UNAUTHORIZED_CHECKOUT_STARTED: null,
  CATEGORY_BACK_TO_TOP_CLICKED: null,
  CATEGORY_BREADCRUMB_CLICKED: null,
  CATEGORY_CHANGED: null,
  CATEGORY_FETCH_FAILED: null,
  CATEGORY_FETCH_STARTED: null,
  CATEGORY_IMAGE_HOVERED: null,
  CATEGORY_LOADED: null,
  CATEGORY_NAV_ITEM_CLICK: null,
  CATEGORY_NAVIGATION_FETCH_FAILED: null,
  CATEGORY_NAVIGATION_FETCH_SUCCEEDED: null,
  CATEGORY_NAVIGATION_ITEM_CLICKED: null,
  CATEGORY_NAVIGATION_ITEM_COLLAPSED: null,
  CATEGORY_NAVIGATION_ITEM_EXPANDED: null,
  CATEGORY_NAVIGATION_REQUEST_STARTED: null,
  CATEGORY_PLP_FETCH_SUCCEEDED: null,
  CATEGORY_CONSTRUCTOR_FETCH_MORE_STARTED: null,
  CATEGORY_CONSTRUCTOR_FETCH_MORE_SUCCEEDED: null,
  CATEGORY_CONSTRUCTOR_FETCH_MORE_FAILED: null,
  CATEGORY_PRODUCT_VARIANT_ACTIVATED: null,
  CATEGORY_PRODUCT_VARIANT_DEACTIVATED: null,
  CATEGORY_PRODUCT_VARIANT_SELECTED: null,
  CATEGORY_PROMO_TILE_CLICKED: null,
  CHECKOUT_CLICKED: null,
  CLIENT_ROUTE_CHANGED: null,
  CLIENT_ROUTE_CHANGE_STARTED: null,
  COMPATIBILITY_MODAL_CLOSE_CLICKED: null,
  CONTENTFUL_ACCORDION_CLICKED: null,
  CONTENTFUL_CAROUSEL_CLICKED: null,
  CONTENTFUL_CAROUSEL_SCROLLED: null,
  CONTENTFUL_CTA_CLICKED: null,
  CONTENTFUL_CHAT_TRIGGER_CLICKED: null,
  CONTENTFUL_FLYOUT_OPEN_CLICKED: null,
  CONTENTFUL_HERO_IMAGE_CLICKED: null,
  CONTENTFUL_INSERTED_CONTENT_FETCH_FAILED: null,
  CONTENTFUL_INSERTED_CONTENT_FETCH_STARTED: null,
  CONTENTFUL_INSERTED_CONTENT_FETCH_SUCCEEDED: null,
  CONTENTFUL_PAGE_FETCH_FAILED: null,
  CONTENTFUL_PAGE_FETCH_STARTED: null,
  CONTENTFUL_PAGE_FETCH_SUCCEEDED: null,
  CONTENTFUL_PAGE_GLOBALS_SET: null,
  CONTENTFUL_LOAD_QUIZ: null,
  CONTENTFUL_MODAL_DISMISSED: null,
  CONTENTFUL_MODAL_OPEN_CLICKED: null,
  CONTENTFUL_ON_PAGE_LOAD_MODAL_OPENED: null,
  CONTENTFUL_PRODUCT_TILE_FETCH_FAILED: null,
  CONTENTFUL_PRODUCT_TILE_FETCH_STARTED: null,
  CONTENTFUL_PRODUCT_TILE_FETCH_SUCCEEDED: null,
  CONTENTFUL_PRODUCT_TILE_PREVIEW_CLICKED: null,
  CONTENTFUL_PRODUCT_TILE_CLICKED: null,
  CONTENTFUL_PRODUCT_TILE_VARIANT_ACTIVATED: null,
  CONTENTFUL_PRODUCT_TILE_VARIANT_DEACTIVATED: null,
  CONTENTFUL_PRODUCT_TILE_VARIANT_SELECTED: null,
  CONTENTFUL_QUIZ_ANSWER_CLICKED: null,
  CONTENTFUL_QUIZ_UPDATE_QUESTIONS_QUEUE: null,
  CONTENTFUL_QUIZ_OUTPUT_LOADING: null,
  CONTENTFUL_QUIZ_SINGLE_ANSWER_CLICKED: null,
  CONTENTFUL_QUIZ_QUESTION_CHANGED: null,
  CONTENTFUL_SAVE_QUIZ: null,
  CONTENTFUL_STORY_POD_CLICKED: null,
  CONTENTFUL_TAB_GROUP_TAB_CLICKED: null,
  CONTENTFUL_VIDEO_PLAYED: null,
  CURRENT_PAGE_CHANGED: null,
  CURRENT_PAGE_INIT: null,
  EDIT_MY_FIT_CLICKED: null,
  EMAIL_CAPTURED: null,
  EMPTY_CATEGORY_FETCHED: null,
  EXCHANGE_INVENTORY_UNIT_OPTIONS_FETCH_SUCCEEDED: null,
  EXCHANGE_INVENTORY_UNIT_OPTIONS_FETCH_FAILED: null,
  EXCHANGE_INVENTORY_UNITS_FETCH_FAILED: null,
  EXCHANGE_INVENTORY_UNITS_FETCH_STARTED: null,
  EXCHANGE_INVENTORY_UNITS_FETCH_SUCCEEDED: null,
  EXCHANGE_ITEM_COMPLETED: null,
  EXCHANGE_ORDER_DETAILS_FETCH_FAILED: null,
  EXCHANGE_ORDER_DETAILS_FETCH_STARTED: null,
  EXCHANGE_ORDER_DETAILS_FETCH_SUCCEEDED: null,
  EXCHANGE_START_CLICKED: null,
  EXCHANGE_SUBMIT_RETURN_AUTHORIZATION_FAILED: null,
  EXCHANGE_SUBMIT_RETURN_AUTHORIZATION_STARTED: null,
  EXCHANGE_SUBMIT_RETURN_AUTHORIZATION_SUCCEEDED: null,
  EXCHANGE_REASON_CODES_FETCH_STARTED: null,
  EXCHANGE_REASON_CODES_FETCH_SUCCEEDED: null,
  EXCHANGE_REASON_CODES_FETCH_FAILED: null,
  EXCHANGE_REASON_CODE_SELECTED: null,
  EXIT_CHECKOUT_FLOW_SUCCEEDED: null,
  FILTER_MODAL_VISIBILITY_TOGGLED: null,
  FILTERS_CLEARED: null,
  FILTERS_CLOSED: null,
  FILTERS_OPENED: null,
  FILTERS_OPTION_VALUE_CLICKED: null,
  FILTERS_UPDATED: null,
  FIT_EDUCATOR_CLOSED: null,
  FIT_EDUCATOR_VIDEO_PLAYED: null,
  FIT_EDUCATOR_OPTION_CLICKED: null,
  FIT_PREFERENCES_CTA_CLICKED: null,
  FIT_PREFERENCES_OPTION_TOGGLED: null,
  FIT_PREFERENCES_OPTION_TYPES_FETCH_FAILED: null,
  FIT_PREFERENCES_OPTION_TYPES_FETCH_STARTED: null,
  FIT_PREFERENCES_OPTION_TYPES_FETCH_SUCCEEDED: null,
  FIT_PREFERENCES_USER_SELECTIONS_FETCH_FAILED: null,
  FIT_PREFERENCES_USER_SELECTIONS_FETCH_STARTED: null,
  FIT_PREFERENCES_USER_SELECTIONS_FETCH_SUCCEEDED: null,
  FIT_PREFERENCES_USER_SELECTIONS_UPDATE_FAILED: null,
  FIT_PREFERENCES_USER_SELECTIONS_UPDATE_STARTED: null,
  FIT_PREFERENCES_USER_SELECTIONS_UPDATE_SUCCEEDED: null,
  FOOTER_LINK_CLICKED: null,
  GIFT_CARD_BALANCE_CHECKED: null,
  GUEST_CHECKOUT_CLICKED: null,
  GUIDESHOP_DATA_FETCH_FAILED: null,
  GUIDESHOP_DATA_FETCH_STARTED: null,
  GUIDESHOP_DATA_FETCH_SUCCEEDED: null,
  HEADER_ACCOUNT_CLICKED: null,
  HEADER_ACCOUNT_MOUSE_ENTERED: null,
  HEADER_ACCOUNT_MOUSE_LEFT: null,
  HEADER_HAS_MINIFIED: null,
  HEADER_NO_LONGER_MINIFIED: null,
  HEADER_NAVIGATION_ITEM_CLICKED: null,
  HEADER_NAVIGATION_ITEM_MOUSE_ENTERED: null,
  HEADER_NAVIGATION_MOUSE_LEFT: null,
  HEADER_NAVIGATION_STATIC_LINK_CLICKED: null,
  HOMEPAGE_IMAGE_TILE_CLICKED: null,
  HOMEPAGE_NAV_SLIDER_ITEM_CLICKED: null,
  HOMEPAGE_PRODUCT_TILE_CLICKED: null,
  JOBS_DATA_FETCH_FAILED: null,
  JOBS_DATA_FETCH_SUCCEEDED: null,
  LEFT_DRAWER_CLOSE_FINISHED: null,
  LEFT_DRAWER_CLOSE_STARTED: null,
  LEFT_DRAWER_LEFT_CTA_CLICKED: null,
  LEFT_DRAWER_OPEN_CLICKED: null,
  LINE_ITEMS_ADDED_TO_CART: null,
  LINE_ITEMS_REMOVED_FROM_CART: null,
  LOQATE_PREPOPULATE_RECEIVED: null,
  MY_FIT_TOGGLED: null,
  NARRATIVE_CLICKED: null,
  NEW_CUSTOMER_EMAIL_LIST_EMAIL_CHANGED: null,
  NEW_CUSTOMER_EMAIL_LIST_MAILING_LIST_FAILED: null,
  NEW_CUSTOMER_EMAIL_LIST_MAILING_LIST_STARTED: null,
  NEW_CUSTOMER_EMAIL_LIST_MAILING_LIST_SUCCEEDED: null,
  OUT_OF_STOCK_SUBSCRIPTION_EMAIL_CHANGED: null,
  OUT_OF_STOCK_SUBSCRIPTION_FORM_VALIDATION_FAILED: null,
  OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_FAILED: null,
  OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_STARTED: null,
  OUT_OF_STOCK_SUBSCRIPTION_SUBMIT_SUCCEEDED: null,
  PAGE_LOADED: null,
  SALESFORCE_CHAT_CLICKED: null,
  SALESFORCE_CHAT_ESTABLISHED: null,
  SITEMAP_FETCH_FAILED: null,
  SITEMAP_FETCH_SUCCEEDED: null,
  SUBMIT_ORDER_REQUEST_STARTED: null,
  TOGGLE_REVIEW_SHIPPING_EDIT: null,
  TOGGLE_REVIEW_BILLING_EDIT: null,
  ORDER_CONFIRMATION_UNMOUNTED: null,
  // Product Detail
  PRODUCT_DETAIL_ADD_TO_CART_CLICKED: null,
  PRODUCT_DETAIL_EDUCATION_CTA_CLICKED: null,
  PRODUCT_DETAIL_OPTION_TOGGLED: null,
  PRODUCT_DETAIL_FETCH_FAILED: null,
  PRODUCT_DETAIL_FETCH_STARTED: null,
  PRODUCT_DETAIL_FETCH_SUCCEEDED: null,
  PRODUCT_DETAIL_GIFT_CARD_INPUT_CHANGED: null,
  PRODUCT_DETAIL_HELP_LINK_CLICKED: null,
  PRODUCT_DETAIL_LOCATION_CHANGED: null,
  PRODUCT_DETAIL_NEXT_IMAGE_CLICKED: null,
  PRODUCT_DETAIL_OPTIONS_CHANGED: null,
  PRODUCT_DETAIL_OPTION_LOADED: null,
  PRODUCT_DETAIL_POWER_REVIEWS_DISPLAYED: null,
  PRODUCT_DETAIL_PREV_IMAGE_CLICKED: null,
  PRODUCT_DETAIL_PRODUCT_PROPERTIES_ACCORDION_CLICKED: null,
  PRODUCT_DETAIL_REQUESTED_WITH_OPTIONS: null,
  PRODUCT_DETAIL_THUMBNAIL_CLICKED: null,
  PRODUCT_DETAIL_ZOOM_CLICKED: null,
  PRODUCT_DETAIL_ZOOM_CLOSE_CLICKED: null,
  PRODUCT_DETAIL_ZOOM_OPEN_CLICKED: null,
  // Product Preview
  PRODUCT_PREVIEW_ADD_TO_CART_CLICKED: null,
  PRODUCT_PREVIEW_EDUCATION_CTA_CLICKED: null,
  PRODUCT_PREVIEW_OPTION_TOGGLED: null,
  PRODUCT_PREVIEW_CONTENTFUL_DATA_FETCH_FAILED: null,
  PRODUCT_PREVIEW_CONTENTFUL_DATA_FETCH_STARTED: null,
  PRODUCT_PREVIEW_CONTENTFUL_DATA_FETCH_SUCCEEDED: null,
  PRODUCT_PREVIEW_FETCH_FAILED: null,
  PRODUCT_PREVIEW_FETCH_STARTED: null,
  PRODUCT_PREVIEW_FETCH_SUCCEEDED: null,
  PRODUCT_PREVIEW_FIT_EDUCATION_LOADED: null,
  PRODUCT_PREVIEW_GIFT_CARD_INPUT_CHANGED: null,
  PRODUCT_PREVIEW_HELP_LINK_CLICKED: null,
  PRODUCT_PREVIEW_LOCATION_CHANGED: null,
  PRODUCT_PREVIEW_OPTIONS_CHANGED: null,
  PRODUCT_PREVIEW_REQUESTED_WITH_OPTIONS: null,
  PRODUCT_PREVIEW_SOLD_OUT_CLICKED: null,
  PRODUCT_PREVIEW_THUMBNAIL_CLICKED: null,
  PRODUCT_PREVIEW_ZOOM_CLICKED: null,
  PRODUCT_PREVIEW_ZOOM_CLOSE_CLICKED: null,
  PRODUCT_PREVIEW_ZOOM_OPEN_CLICKED: null,
  PRODUCT_PREVIEW_CLICKED: null,
  PRODUCT_PREVIEW_CLOSED: null,
  PRODUCT_PREVIEW_VIEWED: null,
  PRODUCT_PREVIEW_MODAL_VIEWED: null,
  PRODUCT_PREVIEW_VIEW_DETAILS_CLICKED: null,
  PRODUCT_PREVIEW_SET_NEW_PRODUCT: null,
  PRODUCT_PREVIEW_RESET_AFTER_CLICK_CLOSE: null,
  PRODUCT_PREVIEW_FETCH_NOT_FOUND: null,
  PRODUCT_RECOMMENDATION_CLICKED: null,
  PRODUCT_RECOMMENDATIONS_DISPLAYED: null,
  PRODUCT_TILE_CLICKED: null,
  PRODUCT_DETAIL_ACCOMPANYING_PRODUCT_CLICKED: null,
  PROMOTION_EXCLUSIONS_FETCH_FAILED: null,
  PROMOTION_EXCLUSIONS_FETCH_STARTED: null,
  PROMOTION_EXCLUSIONS_FETCH_SUCCEEDED: null,
  REMOVE_GIFT_CARD_REQUEST_FAILED: null,
  REMOVE_GIFT_CARD_REQUEST_STARTED: null,
  REMOVE_GIFT_CARD_REQUEST_SUCCEEDED: null,
  RESET_PASSWORD_FAILED: null,
  RESET_PASSWORD_LOADED_WITH_QUERY_TOKEN: null,
  RESET_PASSWORD_SUCCEEDED: null,
  RESET_PASSWORD_FORCED: null,
  RETURN_LABEL_REQUESTED: null,
  RIGHT_DRAWER_CLOSE_FINISHED: null,
  RIGHT_DRAWER_CLOSE_STARTED: null,
  RIGHT_DRAWER_CONTENTS_CHANGED: null,
  RIGHT_DRAWER_OPEN_CLICKED: null,
  SAVE_TO_MY_FIT_CLICKED: null,
  SAVED_ITEMS_EMPTY_LIST_LINK_CLICKED: null,
  SAVED_ITEMS_FETCH_COMPLETED: null,
  SAVED_ITEMS_FETCH_FAILED: null,
  SAVED_ITEMS_FETCH_STARTED: null,
  SAVED_ITEMS_FETCH_SUCCEEDED: null,
  SAVED_ITEMS_PUBLIC_LINK_CLICKED: null,
  SAVED_ITEMS_ADD_PRODUCT_COMPLETED: null,
  SAVED_ITEMS_ADD_PRODUCT_FAILED: null,
  SAVED_ITEMS_ADD_PRODUCT_STARTED: null,
  SAVED_ITEMS_ADD_PRODUCT_SUCCEEDED: null,
  SAVED_ITEMS_REMOVE_PRODUCT_COMPLETED: null,
  SAVED_ITEMS_REMOVE_PRODUCT_FAILED: null,
  SAVED_ITEMS_REMOVE_PRODUCT_STARTED: null,
  SAVED_ITEMS_REMOVE_PRODUCT_SUCCEEDED: null,
  SAVED_ITEMS_LOADED: null,
  SAVED_ITEM_CLICKED: null,
  SAVED_ITEMS_UNAUTHENTICATED_ADD_CLICKED: null,
  SEARCH_AUTOSUGGEST_CLICKED: null,
  SEARCH_FETCH_FAILED: null,
  SEARCH_FETCH_STARTED: null,
  SEARCH_FETCH_SUCCEEDED: null,
  SEARCH_INPUT_CHANGED: null,
  MOBILE_SEARCH_BAR_FOCUS_COMPLETE: null,
  MOBILE_SEARCH_BAR_OPENED_FROM_HEADER: null,
  SEARCH_FOCUSED: null,
  SEARCH_PRODUCT_LINK_CLICKED: null,
  SEARCH_REDIRECT_TRIGGERED: null,
  SEARCH_SET_SOURCE: null,
  SEARCH_SUBMITTED: null,
  SEARCH_PRODUCT_VARIANT_ACTIVATED: null,
  SEARCH_PRODUCT_VARIANT_DEACTIVATED: null,
  SEARCH_PRODUCT_VARIANT_SELECTED: null,
  SET_ACTIVE_PROMOTION: null,
  SET_HEADER_TRANSPARENCY: null,
  SIZE_AND_FIT_CLASSIFICATIONS_FETCH_FAILED: null,
  SIZE_AND_FIT_CLASSIFICATIONS_FETCH_STARTED: null,
  SIZE_AND_FIT_CLASSIFICATIONS_FETCH_SUCCEEDED: null,
  SIZE_AND_FIT_CLASSIFICATION_FETCH_FAILED: null,
  SIZE_AND_FIT_CLASSIFICATION_FETCH_STARTED: null,
  SIZE_AND_FIT_CLASSIFICATION_FETCH_SUCCEEDED: null,
  SIZE_AND_FIT_EDUCATION_ITEM_SCROLLED: null,
  SIZE_AND_FIT_EDUCATION_MODAL_CLOSED: null,
  SIZE_AND_FIT_EDUCATION_TAB_CLICKED: null,
  SIZE_AND_FIT_POINT_OF_MEASUREMENT_CLICKED: null,
  SIZE_AND_FIT_SIZE_CHART_TABLE_CHANGED: null,
  SORT_DROPDOWN_CLICKED: null,
  SORT_DROPDOWN_VALUE_CLICKED: null,
  SORT_MOUSE_ENTERED: null,
  SORT_MOUSE_LEFT: null,
  SUGGESTED_ITEM_ADDED_TO_CART: null,
  SUGGESTED_ITEM_DISMISSED: null,
  SUGGESTED_ITEM_DISPLAYED_IN_CART: null,
  SUGGESTED_ITEM_FETCH_FAILED: null,
  SUGGESTED_ITEM_FETCH_SUCCEEDED: null,
  TIPPY_TOP_DETAILS_TOGGLED: null,
  TIPPY_TOP_DISMISSED: null,
  TIPPY_TOP_HEIGHT_CHANGED: null,
  TIPPY_TOP_LOADED: null,
  TOAST_ADDED: null,
  TOAST_DISMISSED: null,
  TOAST_DISPLAYED: null,
  TOAST_REMOVE_CART_ITEM: null,
  TOAST_OPEN_SAVE_URL: null,
  TOGGLE_EXISTING_USER_LOGIN: null,
  TRACK_MY_FIT_TOGGLED: null,
  UNSUPPORTED_BROWSER_VERSION_DETECTED: null,
  USER_ALREADY_LOGGED_IN: null,
  USER_LOGGED_IN: null,
  USER_LOGGED_OUT: null,
  USER_LOGOUT_STARTED: null,
  USER_NOT_LOGGED_IN: null,
  USER_REGISTERED: null,
  CHANGE_BILLING_INFORMATION_CLICKED: null,
  BILLING_INFORMATION_REVIEW_NAVIGATE_CLICKED: null,
  BILLING_EXISTING_CREDIT_CARD_TOGGLED: null,
  BILLING_INFORMATION_ADD_FAILED: null,
  BILLING_INFORMATION_ADD_SUCCEEDED: null,
  BILLING_INFORMATION_ADDRESS_INPUT_CHANGED: null,
  BILLING_INFORMATION_CHECKBOX_CHANGED: null,
  BILLING_INFORMATION_CREDIT_CARD_INPUT_CHANGED: null,
  BILLING_INFORMATION_EXPIRY_DATE_INPUT_CHANGED: null,
  BILLING_INFORMATION_INPUT_CHANGED: null,
  BILLING_INFORMATION_NAME_SET: null,
  BILLING_INFORMATION_PAYMENT_TYPE_CHANGED: null,
  BILLING_INFORMATION_REQUEST_COMPLETED: null,
  BILLING_INFORMATION_REQUEST_STARTED: null,
  BILLING_INFORMATION_STATE_RESET: null,
  COUNTRIES_FETCH_FAILED: null,
  COUNTRIES_FETCH_SUCCEEDED: null,
  COUNTRIES_REQUEST_COMPLETED: null,
  COUNTRIES_REQUEST_STARTED: null,
  ORDER_CHANGED_EMAIL_CLICKED: null,
  ORDER_EDIT_BILLING_CTA_CLICKED: null,
  ORDER_EMAIL_UPDATE_CANCELLED: null,
  ORDER_EMAIL_UPDATE_FAILED: null,
  ORDER_EMAIL_UPDATE_STARTED: null,
  ORDER_EMAIL_UPDATE_SUCCEEDED: null,
  ORDER_FETCH_FAILED: null,
  ORDER_FETCH_SUCCEEDED: null,
  ORDER_PROMO_CODE_INPUT_CHANGED: null,
  ORDER_NOT_FOUND: null,
  ORDER_EXIT_USERLESS_CHECKOUT_STARTED: null,
  ORDER_EXIT_USERLESS_CHECKOUT_SUCCEEDED: null,
  ORDER_EXIT_USERLESS_CHECKOUT_FAILED: null,
  ORDER_REQUEST_COMPLETED: null,
  ORDER_REQUEST_STARTED: null,
  ORDER_STEP_LOCATION_CHANGED: null,
  ORDER_SUBMIT_COMPLETE_FAILED: null,
  ORDER_SUBMIT_COMPLETE_SUCCEEDED: null,
  ORDER_SUBMIT_PROMO_CODE_FAILED: null,
  ORDER_SUBMIT_PROMO_CODE_SUCCEEDED: null,
  ORDER_SUMMARY_TOGGLED: null,
  ORDER_TRACK_UNEXPECTED_REDIRECT_PATH: null,
  SHIPPING_INFORMATION_COUNTRY_UPDATED: null,
  SHIPPING_INFORMATION_REGION_UPDATED: null,
  SHIPPING_INFORMATION_ZIP_INPUT_CHANGED: null,
  SHIPPING_INFORMATION_REQUEST_STARTED: null,
  SHIPPING_INFORMATION_ADD_FAILED: null,
  INLINE_VALIDATION_FAILED: null,
  INLINE_VALIDATION_SUCCESS: null,
  SHIPPING_INFORMATION_ADD_SUCCEEDED: null,
  SHIPPING_INFORMATION_REQUEST_COMPLETED: null,
  SHIPPING_INFORMATION_INPUT_CHANGED: null,
  ORDER_UPDATE_FAILED: null,
  ORDER_UPDATE_SUCCEEDED: null,
  ORDER_ZIP_INPUT_CHANGED: null,
  REGIONS_UPDATED: null,
  ADDRESS_BOOK_REQUEST_STARTED: null,
  ADDRESS_BOOK_REQUEST_COMPLETED: null,
  ADDRESS_BOOK_FETCH_FAILED: null,
  ADDRESS_BOOK_FETCH_SUCCEEDED: null,
  ADDRESS_BOOK_ACTION_DIALOG_OPEN_CLICKED: null,
  ADDRESS_BOOK_ACTION_DIALOG_CLOSE_CLICKED: null,
  ADDRESS_BOOK_UPDATE_SUCCEEDED: null,
  ADDRESS_BOOK_UPDATE_FAILED: null,
  UPDATE_ORDER_ADDRESS_SUCCEEDED: null,
  UPDATE_ORDER_ADDRESS_FAILED: null,
  ADDRESS_BOOK_DELETE_SUCCEEDED: null,
  ADDRESS_BOOK_DELETE_FAILED: null,
  ADDRESS_BOOK_ACTION_DIALOG_BACK_CLICKED: null,
  ADDRESS_BOOK_ACTION_DIALOG_NEW_CLICKED: null,
  ADDRESS_BOOK_EDIT_CLICKED: null,
  WALLET_ADD_NEW_PAYMENT_CLICKED: null,
  WALLET_ADD_PAYMENT_FAILED: null,
  WALLET_ADD_PAYMENT_SUCCEEDED: null,
  WALLET_AFFIRM_CHECKOUT_SUCCEEDED: null,
  WALLET_BACK_CLICKED: null,
  WALLET_CLOSE_CLICKED: null,
  WALLET_OPEN_CLICKED: null,
  WALLET_DELETE_PAYMENT_CLICKED: null,
  WALLET_DELETE_PAYMENT_FAILED: null,
  WALLET_DELETE_PAYMENT_SUCCEEDED: null,
  WALLET_FETCH_FAILED: null,
  WALLET_FETCH_SUCCEEDED: null,
  WALLET_REQUEST_COMPLETED: null,
  WALLET_REQUEST_STARTED: null,
  ORDER_CONFIRMATION_VIEWED: null,
  ORDER_PAGE_LOCATION_EXITED: null,
  ORDER_SHIPPING_RATE_CHANGED_STARTED: null,
  ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED: null,
  ORDER_SHIPPING_RATE_CHANGED_FAILED: null,
  ORDER_DELETE_LINE_ITEM_SUCCEEDED: null,
  ORDER_DELETE_LINE_ITEM_FAILED: null,
  BILLING_INFORMATION_ZIP_INPUT_CHANGED: null,
  BILLING_INFORMATION_COUNTRY_UPDATED: null,
  BILLING_INFORMATION_REGION_UPDATED: null,
  BILLING_ADD_PAYPAL_FAILED: null,
  PAYPAL_BUTTON_CLICKED: null,
  ORDER_FATAL_ERROR_RECEIVED: null,
  CHECKOUT_STEP_VIEWED: null,
  CHECKOUT_STEP_COMPLETED: null,
  CHECKOUT_STARTED: null,
  PROMO_CODE_ENTERED: null,
  SHIPPING_INFORMATION_LOADED: null,
  AUTH_CLEARED_AND_REDIRECTED: null,
  SECURITY_CODE_VALIDATED: null,
  SECURITY_CODE_VALIDATION_ATTEMPTED: null,
  SECURITY_CODE_VALIDATION_FORM_SHOWN: null,
  PDP_TAB_CLICKED: null,
  // Navigation
  NAV_PILL_ITEM_CLICKED: null,
  NAVIGATION_CLOSED: null,
  NAVIGATION_FETCH_FAILED: null,
  NAVIGATION_FETCH_SUCCEEDED: null,
  NAVIGATION_ITEM_CLICKED: null,
  NAVIGATION_IMAGE_CLICKED: null,
  NAVIGATION_IMAGE_TITLE_CLICKED: null,
  NAVIGATION_LOADED: null,
  NAVIGATION_OPENED: null,
  NAVIGATION_PARENT_TILE_CLICKED: null,
  NAVIGATION_REQUEST_COMPLETED: null,
  NAVIGATION_REQUEST_STARTED: null,
  NAVIGATION_SUBNAV_TILE_CLICKED: null,
  //CCPA
  SIGN_IN_REQUESTED_FOR_CCPA: null,
  SUBMIT_CCPA_REQUEST_STARTED: null,
  SUBMIT_CCPA_REQUEST_COMPLETED: null,
  SUBMIT_CCPA_REQUEST_FAILED: null,
  CCPA_PARAM_DECRYPTED: null,
  CCPA_PARAM_RECEIVED: null,
  TOGGLE_CCPA_LOGIN: null,
  CCPA_REDIRECT_REQUESTED: null,
  ON_RETRY_ERROR: null,
})
