import { fromJS } from "immutable"
import ActionTypes from "highline/redux/action_types"

export const initialState = fromJS({
  code: "",
  promo: fromJS({}),
})

const activePromotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_PROMOTION: {
      return state.merge(
        fromJS({
          code: action.code,
          promo: action.promo,
        })
      )
    }

    default:
      return state
  }
}

export default activePromotionReducer
