import { fromJS, Map } from "immutable"
import ActionTypes from "highline/redux/action_types"

const initialState = fromJS({
  loaded: false,
  activeABTests: Map(),
})

const abTastyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AB_TASTY_LOADED:
      return state.set("loaded", true)

    case ActionTypes.AB_TESTS_FETCHED:
      if (!action.abTests) return state
      return state.set("activeABTests", fromJS(action.abTests))

    default:
      return state
  }
}

export default abTastyReducer
