export const AB_TESTS = {
  // ABTests from ABTasty:
  // loqateShipping: "Website (DT/MW) - Checkout - Address Validation Service",
  plpHoverImageOverrideDesktop: "PLP-Hover-as-default-desktop",
  singlePageCheckout: "Single Page Checkout",
  singlePageGuestCheckout: "Single-Page-Guest-Checkout",
  guestCheckout: "Guest-Checkout",
  fullPageCart: "Full Page Cart",
  fitVideo: "Fit-Video",
  pdpVideo: "PDP-Video",
}
