import getConfig from "highline/config/application"
import Rollbar from "highline/utils/rollbar"
import serverRequire from "highline/utils/server_require"
import axios from "axios"
import { fromJS } from "immutable"
import { isServer } from "highline/utils/client"

const fs = serverRequire("fs-extra")
const contentful = require("contentful")
const config = getConfig()

const publishedClient = !config.contentfulEnvironment
  ? {}
  : contentful.createClient({
      accessToken: config.contentfulAccessToken,
      environment: config.contentfulEnvironment,
      space: config.contentfulSpace,
    }).withoutUnresolvableLinks

const previewClient = !config.contentfulEnvironment
  ? {}
  : contentful.createClient({
      accessToken: config.contentfulPreviewAccessToken,
      environment: config.contentfulEnvironment,
      host: config.contentfulPreviewHost,
      space: config.contentfulSpace,
    })

const contentfulFetch = async (isPreview, contentType) => {
  const client = isPreview ? previewClient : publishedClient

  let contentfulResponse
  try {
    contentfulResponse = await client.getEntries({
      content_type: contentType,
      include: 10, //Include nested content data up to 10 levels
      limit: 1000, //Hard limit of 1000 items from Contentful
    })
  } catch (err) {
    // on critical contentful failure, use backup json
    Rollbar.error(`Contentful Response Failed for ${contentType} - Err: ${err}: Backup data used`)
    await fetchStaticContentfulData("contentful_backup_data.json")
  }

  if (contentfulResponse.items.length < 1) {
    Rollbar.error(`204: Contentful Response Empty for ${contentType}: Backup data used`)
    await fetchStaticContentfulData("contentful_backup_data.json")
  }

  return contentfulResponse.items
}

export const fetchContentfulDataAsync = async (contentType, isPreview = false) => {
  if (isPreview) return await contentfulFetch(isPreview, contentType) // can prob remove this line, but want to do so iteratively
  if (config.isFeatureMode)
    return await fetchStaticContentfulData("mock_data/contentful_test_data.json")
  if (config.isContentfulDisabled)
    return await fetchStaticContentfulData("contentful_backup_data.json")

  return await contentfulFetch(isPreview, contentType)
}

const fetchStaticContentfulData = async (filename) => {
  try {
    if (isServer) {
      const fileData = await fs.readFile(`./static/${filename}`)
      return fromJS(JSON.parse(fileData))
    } else {
      const response = await axios.get(`/static/${filename}`)
      return fromJS(response.data)
    }
  } catch (err) {
    Rollbar.error(`fetchLocalContentfulData err: ${err}`)
  }
}

const baseContentfulQuery = {
  include: 10,
  limit: 1000,
}

export const fetchContentfulPageAsync = async (url, isPreview = false) => {
  if (url === "/") {
    url = "index"
  }

  if (url && url[0] === "/") {
    url = url.substring(1)
  }

  const query = {
    ...baseContentfulQuery,
    content_type: "contentfulPage",
    "fields.url": url,
  }
  const staticKey = `contentfulData-contentfulPage-${encodeURIComponent(url)}`

  return await getContentfulData(query, staticKey, isPreview)
}

export const fetchContentfulArticleAsync = async (url, isPreview = false) => {
  if (url === "/") {
    url = "index"
  }

  if (url && url[0] === "/") {
    url = url.substring(1)
  }

  const query = {
    ...baseContentfulQuery,
    content_type: "article",
    "fields.url": url,
  }
  const staticKey = `contentfulData-article-${encodeURIComponent(url)}`

  return await getContentfulData(query, staticKey, isPreview)
}

export const fetchContentfulPageExtrasAsync = async (pathPrefix, isPreview = false) => {
  const query = {
    ...baseContentfulQuery,
    content_type: "pageExtras",
    "fields.exactPathPrefix": pathPrefix,
  }
  const staticKey = `contentfulData-pageExtras-${encodeURIComponent(pathPrefix)}`
  return await getContentfulData(query, staticKey, isPreview)
}

export const fetchContentfulContentInsertionAsync = async (
  targets,
  isPreview = false,
  redisAlias = null
) => {
  const joinedTargets = targets.join(",")
  const query = {
    ...baseContentfulQuery,
    content_type: "contentInsertion",
    "fields.target[in]": joinedTargets,
  }
  const staticKey = redisAlias ? redisAlias : `contentfulData-${joinedTargets}`
  return await getContentfulData(query, staticKey, isPreview)
}

const getContentfulData = async (query, staticKey, isPreview) => {
  if (config.isFeatureMode) return await fetchStaticContentfulData(`mock_data/${staticKey}.json`)

  try {
    const client = isPreview ? previewClient : publishedClient
    const contentfulResponse = await client.getEntries(query)

    if (!contentfulResponse || contentfulResponse.items.length === 0) {
      return undefined
    }

    return fromJS(contentfulResponse.items)
  } catch (err) {
    Rollbar.error(`Unexpected exception when querying Contentful: ${err}`)
    return undefined
  }
}

export const getContentfulAssetUrl = async (assetId, isPreview) => {
  const client = isPreview ? previewClient : publishedClient

  return await client.getAsset(assetId).then((asset) => asset.fields.file.url)
}
