import { connect } from "react-redux"
import CategoryNavigation from "highline/components/category/category_navigation"
import {
  categoryNavigationItemClicked,
  categoryNavigationItemCollapsed,
  categoryNavigationItemExpanded,
} from "highline/redux/actions/category_navigation_actions"

const mapStateToProps = (state, ownProps) => ({
  expandedItemSiblings: state.getIn(["categoryNavigation", "expandedItemSiblings"]),
  activeItem: state.getIn(["categoryNavigation", "activeItem"]),
  categoryNavItems: state.getIn(["categoryNavigation", "navItems"]),
  expandedItem: state.getIn(["categoryNavigation", "expandedItem"]),
  showForSmartPhoneAndTablet: ownProps.showForSmartPhoneAndTablet,
  categoryIsCollection: state.getIn(["category", "isCollection"]),
})

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (item) => {
      dispatch(categoryNavigationItemClicked(item))
    },
    onCollapse: (item) => {
      dispatch(categoryNavigationItemCollapsed(item))
    },
    onExpand: (item) => {
      dispatch(categoryNavigationItemExpanded(item))
    },
  }
}

const CategoryNavigationContainer = connect(mapStateToProps, mapDispatchToProps)(CategoryNavigation)

export default CategoryNavigationContainer
