import ActionTypes from "highline/redux/action_types"
import * as PromotionExclusionsApi from "highline/api/promotion_exclusions_api"
import { getActivePromo, isActivePromoValid } from "highline/redux/helpers/product_detail_helper"
import { getField } from "highline/utils/contentful/contentful_helper"
import Rollbar from "highline/utils/rollbar"
import { extractQueryParams } from "highline/utils/url"
import { setActivePromotion } from "highline/redux/actions/promotion_actions"
import {
  promotionExclusionsFetchStarted,
  promotionExclusionsFetchSucceeded,
  promotionExclusionsFetchFailed,
} from "highline/redux/actions/promotion_exclusions_actions.js"
import { fromJS } from "immutable"
import localStorageHelper from "highline/utils/local_storage"

async function getPromotionExclusions(currentActivePromoCode) {
  const savedPromotionExclusions = localStorageHelper.getItemWithTTL("promotionExclusions")

  if (savedPromotionExclusions) {
    return fromJS(savedPromotionExclusions)
  }

  const promotionExclusionsResponse =
    await PromotionExclusionsApi.fetchPromotionExclusions(currentActivePromoCode)
  // Expiration date 2 hours
  const ttl = 2 * 60 * 60 * 1000
  localStorageHelper.setItemWithTTL(
    "promotionExclusions",
    promotionExclusionsResponse.data.toJS(),
    ttl
  )

  return promotionExclusionsResponse.data
}

const promotionExclusionsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case ActionTypes.PAGE_LOADED:
      const state = store.getState()
      const contentfulGlobal = state.getIn(["contentful", "globals"])

      let currentActivePromo = state.getIn(["activePromotion", "promo"])
      let currentActivePromoCode = getField(currentActivePromo, "promoCode")?.toLowerCase()

      const isPromoValid = isActivePromoValid(contentfulGlobal, currentActivePromo)
      const queryPromoCode = extractQueryParams(window?.location?.search)?.offer?.toLowerCase()
      const isNewQueryPromo = queryPromoCode && currentActivePromoCode !== queryPromoCode

      // AB Test Clear
      if (queryPromoCode === "clear") {
        store.dispatch(setActivePromotion("", {}))
        localStorage.removeItem("activePromotion")
        return
      }

      if (!isPromoValid || isNewQueryPromo) {
        currentActivePromo = getActivePromo(contentfulGlobal, queryPromoCode)
        currentActivePromoCode = getField(currentActivePromo, "promoCode")?.toLowerCase()

        const isPromoValid = isActivePromoValid(contentfulGlobal, currentActivePromo)

        if (currentActivePromo && isPromoValid) {
          store.dispatch(setActivePromotion(currentActivePromoCode, currentActivePromo))
          localStorage.setItem("activePromotion", JSON.stringify(currentActivePromoCode))
        }
      }

      if (currentActivePromo) {
        store.dispatch(promotionExclusionsFetchStarted(currentActivePromoCode))

        getPromotionExclusions(currentActivePromoCode)
          .then((promotionExclusionsData) => {
            store.dispatch(
              promotionExclusionsFetchSucceeded(
                currentActivePromoCode,
                promotionExclusionsData,
                currentActivePromo
              )
            )
          })
          .catch((error) => {
            store.dispatch(promotionExclusionsFetchFailed(currentActivePromoCode, error))
            Rollbar.error("Promotion Exclusions Fetch Failed", error)
          })
      }

      break
  }

  return next(action)
}

export default promotionExclusionsMiddleware
