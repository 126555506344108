import Rollbar, { formatHttpError } from "highline/utils/rollbar"
import getConfig from "highline/config/application"
import { isServer } from "highline/utils/client"
import { toCamelizedImmutable } from "highline/utils/immutable_helper"

// API CONFIG
export const apiURL = getConfig().apiUrl
export const prefix = "api"
export const authPrefix = "user/spree_user/auth"
export const baseURL = `${apiURL}/${prefix}`
export const authURL = `${apiURL}/${authPrefix}`
export const schema = "gramercy"
export const clientAuthToken = getConfig().clientAuthToken
export const adminClientAuthToken = getConfig().adminClientAuthToken

export const performRequest = async (client, options) => {
  try {
    const response = await client.request(options)
    response.data = toCamelizedImmutable(response.data)

    return response
  } catch (error) {
    logHttpError(isServer ? "Server" : "Client", error)

    if (error.response) {
      const response = error.response
      response.data = toCamelizedImmutable(response.data)
      throw response // surface failed api call
    } else {
      throw error
    }
  }
}

// Log all 500 level http errors
export const logHttpError = (location, error) => {
  if (error.response && error.response.status >= 500) {
    Rollbar.error(`${location} HTTP Error: ${error.response.status}`, formatHttpError(error))
  }
}
