import { fromJS, List } from "immutable"
import ActionTypes from "highline/redux/action_types"

const initialState = fromJS({
  showToast: false,
  url: null,
  toasts: [],
})

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOAST_ADDED:
      // For the moment, The TOAST mechanism only shows one at a time
      // If in the future we want to show more than one, use: state.get("toasts").push(action.toast).toJS() instead
      // and fix the toast style to show more than one toast in a column
      return state.merge({
        showToast: true,
        toasts: [action.toast],
      })

    case ActionTypes.TOAST_DISMISSED:
      if (action.manualDismiss) {
        return state.merge({
          showToast: false,
          url: null,
          toasts: [],
        })
      }

      const remainingToasts = state.get("toasts").pop().toJS()
      if (remainingToasts.length !== 0) {
        return state.merge({
          showToast: true,
          toasts: remainingToasts,
        })
      }
      return state.merge({
        showToast: false,
        url: null,
        toasts: remainingToasts,
      })

    case ActionTypes.TOAST_DISPLAYED:
      return state.merge({
        showToast: true,
      })

    case ActionTypes.TOAST_REMOVE_CART_ITEM:
      const newToasts = []
      state.get("toasts").map((toast) => {
        let lineItems = []
        if (toast.get("lineItems").size > 1) {
          lineItems = toast.get("lineItems").filter((lineItem) => {
            return !(lineItem.get("sku") === action.sku)
          })
          const newToast = toast.merge({ lineItems })
          newToasts.push(newToast)
        } else {
          if (!(toast.get("lineItems").first().get("sku") === action.sku)) {
            newToasts.push(toast)
          }
        }
      })

      return state.merge({
        toasts: List(newToasts),
        showToast: newToasts.length ? true : false,
      })

    // In the near future this reducer case should
    // be remove and uso a custom hook to find the previous URL
    // Recommended solution
    // https://github.com/vercel/next.js/discussions/36723#discussioncomment-2698954
    case ActionTypes.TOAST_OPEN_SAVE_URL:
      if (state.get("toasts").size > 0) {
        return state.merge({
          url: action.url,
        })
      }
      return state

    default:
      return state
  }
}

export default toastReducer
