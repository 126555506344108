import { get } from "highline/api/v2_client"
import { clientAuthToken } from "highline/api/base"

export const fetchPromotionExclusions = async (code) => {
  const response = await get(
    `/promotion_exclusions`,
    { code },
    {
      Accept: "application/json;v=2.0;schema=gramercy",
      "X-Client-Authentication-Token": clientAuthToken,
    }
  )
  return response
}
