import { camelize } from "humps"
import { List, Map } from "immutable"
import { convertPrice } from "highline/utils/product_mapper_helper"
import Rollbar, { formatHttpError } from "highline/utils/rollbar"
import { getField, getObjectByIdentifier } from "highline/utils/contentful/contentful_helper"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"
import { fielderPrefix } from "highline/utils/product_mapper_helper"
import { convertProductIdForAnalytics } from "highline/utils/segment/segment_helper"
import * as ProductDetailApi from "highline/api/product_detail_api"

// Camelize option names to match keys
export const camelizeOptionNames = (options) =>
  options.map((option) => option.set("name", camelize(option.get("name"))))

export const handleOptionChange = (state, action) => {
  const optionName = action.optionName
  const optionValue = action.optionValue

  if (optionValue) {
    state = state.setIn(["selectedOptions", optionName], optionValue)
  } else if (optionCanBeDeselected(state, action)) {
    state = state.deleteIn(["selectedOptions", optionName])
  }

  return state
}

// Update remaining options for selection
export const updateRemainingOptions = (state) => {
  const remainingOptions = state
    .get("options")
    .filter((optionType) => !state.hasIn(["selectedOptions", optionType.get("name")]))

  return state.set("remainingOptions", remainingOptions)
}

// Legacy flatiron API sends images as lists of strings rather than of objects.
// However, highline assumes images are objects with `url` and `caption` properties.
// TODO - remove after flatiron PR 4915 is merged and deployed
// (https://github.com/bonobos/flatiron/pull/4915)
export const ensureImagesAreObjects = (images) => {
  return images.map((imageData) => {
    if (typeof imageData === "string") {
      return Map({
        caption: "",
        url: imageData,
      })
    } else {
      return imageData
    }
  })
}

// Update whether product can be added to cart
export const updateIsPurchasable = (state) => {
  return state.set("isPurchasable", Boolean(state.get("variant")))
}

// Clear errors if user changes one of the existing selections
export const updateErrors = (state, prevState) => {
  if (state.get("remainingOptions").isEmpty()) return state.set("showErrors", false)

  const remainingOptionsAreSame = prevState
    .get("remainingOptions")
    .equals(state.get("remainingOptions"))
  const selectedOptionsAreDifferent = !prevState
    .get("selectedOptions")
    .equals(state.get("selectedOptions"))

  if (remainingOptionsAreSame && selectedOptionsAreDifferent) {
    return state.set("showErrors", false)
  }

  return state
}

// Only allow option to be de-selected under
// certian circumstances
function optionCanBeDeselected(state, action) {
  if (action.optionName === "color") return false

  const options = state
    .get("options")
    .find((optionType) => optionType.get("name") === action.optionName)

  return options.get("values").count() > 1
}

export const productDetailSegmentProperties = (product) => {
  const isMarkdown = Boolean(product.getIn(["price", "onSale"]))
  const price = isMarkdown
    ? product.getIn(["price", "price"], "0")
    : product.getIn(["price", "fullPrice"], "0")

  const sku = product.getIn(["analytics", "selectedVariant", "sku"], "")
  const program_id = product.get("sku")

  return {
    currency: "USD",
    is_bundle: false,
    is_markdown: isMarkdown,
    name: product.get("name"),
    price: parseInt(price.replace("$", "")),
    sku,
    program_id,
    product_id_for_analytics: convertProductIdForAnalytics(sku),
    variant: product.get("selectedOptions").toJS(),
  }
}

export const productNameAndIdDetails = (state) => {
  return {
    product_id: state.get("sku"),
    product_name: state.get("name"),
  }
}

export const updateCollapsedOptions = (state) => {
  const selectedOptions = state.get("selectedOptions")
  let updatedCollapsedOptions = List()

  selectedOptions.forEach((_, selectedOptionKey) => {
    updatedCollapsedOptions = updatedCollapsedOptions.push(selectedOptionKey)
  })

  return updatedCollapsedOptions
}

export const filterFinalSaleSwatches = (state) => {
  const swatches = state.getIn(["options", "0", "values"])
  if (!swatches) return state
  const isFinalSale = state.get("finalSale")
  const finalSaleSwatches = swatches.filter((value) =>
    isFinalSale ? value.get("finalSale") : !value.get("finalSale")
  )
  const values = finalSaleSwatches.sort((a, b) => {
    if (a.get("price") === b.get("price")) return 0
    return a.get("price") > b.get("price") ? 1 : -1
  })

  return state.setIn(["options", "0", "values"], values)
}

export const deduplicateProductProperties = (properties) => {
  if (!properties) return properties
  return properties.map((property) => List([...new Set(property)]))
}

export const getDiscountedPrice = (price, discount) => Math.ceil(price * ((100 - discount) / 100))

export const getDiscountedPriceWithDecimals = (price, discount) =>
  (price * ((100 - discount) / 100)).toFixed(2).replace(/[.,]00$/, "")

export const updateSwatchPrice = (state, promo) => {
  const isFinalSale = state.get("finalSale")
  const swatches = state.getIn(["options", "0", "values"])
  if (isFinalSale || !swatches) return state
  const promoDiscount = getField(promo, "discount")
  const values = swatches.map((swatch) => {
    const discountedPrice = getDiscountedPrice(swatch.get("price"), promoDiscount)
    swatch = swatch.set("price", discountedPrice)
    return swatch
  })
  return state.setIn(["options", "0", "values"], values)
}

export const getPromo = (contentfulData) => {
  const autoAppliedPromos =
    contentfulData &&
    getField(getObjectByIdentifier(contentfulData, "target", "Auto-Apply Promo"), "content")
  return (
    autoAppliedPromos &&
    autoAppliedPromos.find(
      (promo) => getField(promo, "autoapplyPromoType")?.toUpperCase() === "SITEWIDE"
    )
  )
}

export const getAllPromos = (contentfulData) => {
  return contentfulData
    ? getField(getObjectByIdentifier(contentfulData, "target", "Auto-Apply Promo"), "content") || []
    : []
}

export const getLocalPromoCode = () => {
  return localStorage.getItem("activePromotion")
    ? JSON.parse(localStorage.getItem("activePromotion"))
    : null
}

export const getActivePromo = (contentfulData, urlOfferParam) => {
  const allPromos = getAllPromos(contentfulData)

  const queryPromo = allPromos.find(
    (promo) =>
      getField(promo, "promoCode")?.toLowerCase() === urlOfferParam &&
      getField(promo, "autoapplyPromoType")?.toUpperCase() !== "SITEIWDE"
  )

  return (
    queryPromo || getPromoByCode(contentfulData, getLocalPromoCode()) || getPromo(contentfulData)
  )
}

const getPromoByCode = (contentfulData, promoCode) => {
  const allPromos = getAllPromos(contentfulData)

  return allPromos.find((promo) => getField(promo, "promoCode")?.toLowerCase() === promoCode)
}

export const isActivePromoValid = (contentfulData, activePromo) => {
  const allPromos = getAllPromos(contentfulData)

  return allPromos.some(
    (promo) => getField(promo, "promoCode") === getField(activePromo, "promoCode")
  )
}

const updateBundlePriceWithPromo = (state, promoDiscount, exclusions) => {
  let bundlePrice = 0
  const slug = state.get("slug")
  const selectedColorPrices = state.get("products").map((pdt) => ({
    color: pdt.get("selectedOptions").get("color"),
    price: pdt.get("price").get("price"),
  }))

  selectedColorPrices.map((selection) => {
    if (exclusions.isEmpty() || shouldExcludeProgram(slug, selection.color, exclusions?.toJS())) {
      bundlePrice += Number(selection.price.substring(1))
    } else {
      bundlePrice += Number(
        getDiscountedPriceWithDecimals(selection.price.substring(1), promoDiscount)
      )
    }
  })

  return bundlePrice
}

export const updatePriceWithPromo = (state, promo, isBundle = false, exclusions) => {
  if (!promo) return state
  const promoDiscount = getField(promo, "discount")
  let bundlePromoPriceNumeric = 0
  if (isBundle)
    bundlePromoPriceNumeric = updateBundlePriceWithPromo(state, promoDiscount, exclusions)

  const slug = state.get("slug")
  const selectedColor = !isBundle ? state.get("selectedOptions").get("color") : ""
  if (shouldExcludeProgram(slug, selectedColor, exclusions?.toJS())) {
    const promoCode = getField(promo, "promoCode")
    return state.setIn(["price", "promoPrice"], "").setIn(["price", "promoCode"], promoCode)
  }

  const priceNumeric = state.getIn(["price", "priceNumeric"])
  if (priceNumeric && promoDiscount) {
    const productPromoPrice = convertPrice(
      getDiscountedPriceWithDecimals(priceNumeric, promoDiscount)
    )
    const bundlePromoPrice =
      bundlePromoPriceNumeric !== priceNumeric ? convertPrice(bundlePromoPriceNumeric) : null

    const promoPrice = isBundle ? bundlePromoPrice : productPromoPrice
    const promoCode = bundlePromoPriceNumeric !== priceNumeric ? getField(promo, "promoCode") : null

    const newState = state
      .setIn(["price", "promoPrice"], promoPrice)
      .setIn(["price", "promoCode"], promoCode)
    return newState
  }

  return state
}

// only "options" has the purchasability information
export const allSelectedOptionsPurchasable = (selectedOptions, options) => {
  try {
    return List(selectedOptions).every((keyValueList) => {
      const optionTypeName = keyValueList[0]
      const optionValueName = keyValueList[1]

      return options
        .find((optionType) => optionType.get("name") === optionTypeName)
        .get("values")
        .find((optionValue) => optionValue.get("name") === optionValueName)
        .get("purchasable")
    })

    // Returning undefined tells the caller that the selection options are missing data
    // This is set in place to make sure we just disable those purchase attempts instead of
    // sending the user to an error page due to an exception
  } catch (e) {
    return undefined
  }
}

export const getLoadingButtonText = (
  isPreorder,
  selectedOptions,
  options,
  isFinalSale,
  variantIsOutOfStock,
  price
) => {
  if (isPreorder) return "Preorder"
  if (variantIsOutOfStock) return "Notify Me" // Shouldn't need this but do for now
  if (allSelectedOptionsPurchasable(selectedOptions, options)) return "Add to Cart"
  if (isFinalSale) return "Sold Out"

  return "Notify Me"
}

export const getOnlyOptions = (productOptions) => {
  if (!productOptions || productOptions.size === 0) return {}

  return (
    productOptions

      // filter only options with only one purchasable option
      .filter(
        (option) => option.get("values").filter((choice) => choice.get("purchasable")).size === 1
      )

      // and make a map with those options that looks like:
      // { optionA: valueA, optionB: valueB, ... }
      .reduce((optionsMap, option) => {
        const optionName = option.get("name")
        const optionValue = option
          .get("values")
          .filter((choice) => choice.get("purchasable"))
          .getIn([0, "name"])
        optionsMap[optionName] = optionValue
        return optionsMap
      }, {})
  )
}

export const isProductAFielderProduct = (productSlug) => {
  return productSlug.startsWith(fielderPrefix)
}

// images: array of images
// sign: (+1 if going to the next image, or -1 if going to the previous image)
// returns the next zoomImageIndex
export const incrementOrDecrementZoomIndex = (state, images, sign = 1) => {
  sign = Math.sign(sign)
  const size = images.size
  const zoomImageIndex = state.get("zoomImageIndex")
  if (0 <= zoomImageIndex + sign && zoomImageIndex + sign < size) {
    return zoomImageIndex + sign
  } else {
    return sign > 0 ? 0 : size - 1
  }
}

export const getInStock = async (slug, color) => {
  try {
    const response = await ProductDetailApi.fetch(slug, {
      color,
    })

    return response.data.get("analytics").get("selectedVariant").get("inStock")
  } catch (error) {
    if (error.status !== 404) {
      Rollbar.error("Product In Stock Request Failed", formatHttpError(error))
    }

    return false
  }
}
