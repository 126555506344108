import { fromJS } from "immutable"
import ActionTypes from "highline/redux/action_types"
import { extractQueryParams } from "highline/utils/url"
import { isFeatureEnabled } from "highline/utils/abtasty_helper"

const initialState = fromJS({
  cmsContent: null,
  contents: "",
  isOpen: false,
  swappableContents: null,
})

const rightDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLIENT_ROUTE_CHANGE_STARTED:
    case ActionTypes.RIGHT_DRAWER_CLOSE_FINISHED:
      return state.merge({
        cmsContent: null,
        contents: "",
        isOpen: false,
      })

    case ActionTypes.RIGHT_DRAWER_CLOSE_STARTED:
    case ActionTypes.CART_ADD_LINE_ITEMS_FAILED:
      return state.set("isOpen", false)

    case ActionTypes.RIGHT_DRAWER_CONTENTS_CHANGED:
      return state.merge({ contents: action.contents, swappableContents: action.swappableContents })

    case ActionTypes.RIGHT_DRAWER_OPEN_CLICKED:
      return state.set("isOpen", true)

    case ActionTypes.PRODUCT_PREVIEW_CLICKED:
    case ActionTypes.CONTENTFUL_PRODUCT_TILE_PREVIEW_CLICKED:
      return state.merge({
        contents: "quickShop",
        isOpen: true,
        swappableContents: action.swappableContents,
      })

    case ActionTypes.CART_UNAUTHORIZED_CHECKOUT_STARTED:
      return state.merge({
        contents: "auth",
        swappableContents: null,
      })

    case ActionTypes.CART_PAGE_OPENED:
    case ActionTypes.ORDER_EXIT_USERLESS_CHECKOUT_SUCCEEDED:
    case ActionTypes.EXIT_CHECKOUT_FLOW_SUCCEEDED:
      return state

    case ActionTypes.CART_ADD_LINE_ITEMS_STARTED:
      return state.merge({
        contents: "cart",
        isOpen: false,
        swappableContents: null,
      })

    case ActionTypes.CONTENTFUL_FLYOUT_OPEN_CLICKED:
      return state.merge({ cmsContent: action.content, contents: "contentful", isOpen: true })

    case ActionTypes.SAVED_ITEMS_UNAUTHENTICATED_ADD_CLICKED:
      return state.merge({
        contents: "auth",
        isOpen: true,
        swappableContents: null,
      })

    case ActionTypes.PAGE_LOADED:
    case ActionTypes.CLIENT_ROUTE_CHANGED: {
      const query = fromJS(extractQueryParams(window.location.search))
      const shouldCartOpen = query.get("cart") === "open"
      if (shouldCartOpen) {
        return state.merge({
          contents: "cart",
          isOpen: shouldCartOpen,
        })
      }
      return state
    }

    default:
      return state
  }
}

export default rightDrawerReducer
