import Lockr from "lockr"
import * as Cookies from "highline/utils/cookies"

export const isLocalStorageSupported = () => {
  const testKey = "test",
    storage = window.localStorage
  try {
    storage.setItem(testKey, "1")
    storage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

export const storeInLocalStorageWithCookieFallback = (key, value) => {
  if (isLocalStorageSupported()) {
    Lockr.set(key, value)
  } else {
    Cookies.set(key, value)
  }
}

export const readFromLocalStorageOrCookie = (key) => {
  if (isLocalStorageSupported()) {
    return Lockr.get(key)
  } else {
    return Cookies.get(key)
  }
}

function getItemWithTTL(key) {
  const savedData = localStorage.getItem(key)

  if (!savedData) {
    return
  }

  const { expiresAt, data } = JSON.parse(savedData)
  const date = new Date().getTime()

  if (date <= expiresAt) {
    return data
  }

  localStorage.removeItem(key)
}

function setItemWithTTL(key, data, ttl = 0) {
  const expiresAt = new Date().getTime() + ttl
  const obj = {
    expiresAt,
    data,
  }

  localStorage.setItem(key, JSON.stringify(obj))
}

const localStorageHelper = {
  getItemWithTTL,
  setItemWithTTL,
}

export default localStorageHelper
