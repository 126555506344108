import getConfig from "highline/config/application"
import { bonobosEmail } from "highline/utils/inline_validation_helpers"
import { isClient } from "highline/utils/client"

const { google2faEnabled } = getConfig()

export function isSSOEnabledByQueryParam() {
  if (!isClient) return false

  const { searchParams } = new URL(window.location.href)

  return searchParams.get("enableSSO") === "true"
}

export function isEmployeeLoginEnabled(email) {
  return (isSSOEnabledByQueryParam() || google2faEnabled) && bonobosEmail.test(email)
}

export function getAdminPathKeys() {
  const basePathKey = { redirect_to: "/" }

  if (!isClient) return basePathKey

  const enableSSOParam = isSSOEnabledByQueryParam()

  if (!enableSSOParam) return basePathKey

  return { ...basePathKey, enableSSO: enableSSOParam }
}
