import { fromJS } from "immutable"
import { bundleSlugsExclusions } from "highline/utils/promo_auto_apply_helper.js"
import ActionTypes from "highline/redux/action_types"
import { LOAD_STATUS } from "highline/utils/constants"

const { INITIAL, PENDING, SUCCEEDED, FAILED } = LOAD_STATUS

const initialState = fromJS({
  exclusions: {},
  status: INITIAL,
  code: "",
})

const promotionExclusionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PROMOTION_EXCLUSIONS_FETCH_STARTED: {
      return state.merge(
        fromJS({
          exclusions: {},
          code: action.code,
          status: PENDING,
        })
      )
    }

    case ActionTypes.PROMOTION_EXCLUSIONS_FETCH_SUCCEEDED: {
      return state.merge({
        code: action.code,
        exclusions: fromJS({ ...action.data.toJS(), ...bundleSlugsExclusions }),
        status: SUCCEEDED,
      })
    }

    case ActionTypes.PROMOTION_EXCLUSIONS_FETCH_FAILED: {
      return state.set("status", FAILED)
    }

    default:
      return state
  }
}

export default promotionExclusionsReducer
