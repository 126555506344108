import { get } from "highline/api/v2_client"

export const fetch = (page) => {
  return get(`/page_extras/${page}`)
}

const pageExtrasApi = {
  fetch,
}

export default pageExtrasApi
