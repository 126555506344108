import ActionTypes from "highline/redux/action_types"
import * as Cookies from "highline/utils/cookies"
import { extractQueryParams } from "highline/utils/url"

const vendorMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case ActionTypes.PAGE_LOADED:
      const query = extractQueryParams(window.location.search)

      if (!query.ttclid) break

      const now = new Date()
      const expires = new Date(now.setTime(now.getTime() + 1 * 3600 * 1000 * 24))
      Cookies.set("ttclid", query.ttclid, { expires })
      break
  }

  return next(action)
}

export default vendorMiddleware
